import 'src/pages/Admin/Providers/Active/adminprovidersactive.scss'
import { Menu } from 'antd'
import PropTypes from 'prop-types'

const ListingsMenu = ({ handleProfileGroups, handleCreateProfile }) => {
    return (
        <Menu>
            {process.env.REACT_APP_FLAG_SHOW_ADMIN_PROFILE_GROUPS ===
                'true' && (
                <Menu.Item
                    onClick={handleProfileGroups}
                    data-testid="manage-profile-groups"
                >
                    <span className="AdminProvidersActiveDropdownLinks">
                        Manage profile groups
                    </span>
                </Menu.Item>
            )}
            <Menu.Item
                onClick={handleCreateProfile}
                data-testid="create-consumer-profile"
            >
                <span className="AdminProvidersActiveDropdownLinks">
                    Create a new consumer profile
                </span>
            </Menu.Item>
        </Menu>
    )
}

ListingsMenu.propTypes = {
    handleProfileGroups: PropTypes.func.isRequired,
    handleCreateProfile: PropTypes.func.isRequired,
}

export default ListingsMenu
