import { v4 as uuidv4 } from 'uuid'
import { ReactComponent as User } from 'src/assets/images/user-icon.svg'
import { ReactComponent as Id } from 'src/assets/images/id.svg'
import { ReactComponent as Briefcase } from 'src/assets/images/briefcase.svg'
import { ReactComponent as Delivery } from 'src/assets/images/delivery.svg'
import building from 'src/assets/images/building.svg'
import mapPin from 'src/assets/images/map-pin.svg'
import phone from 'src/assets/images/phone-small.svg'
import {
    CREATE_BUSINESS_ACCOUNT,
    CREATE_RESIDENTIAL_ACCOUNT,
} from 'src/routes/pathnames'

export const createResidentialAccountSteps = estimation_id => {
    return [
        {
            action: 'update-basic-info',
            title: 'Basic Information',
            admin_title: `Customer's Basic Information`,
            description:
                'Your personal & employment info are required to create your account.',
            icon: <User />,
            link: '/consumer/account-setup/residential-information',
            admin_link: `/admin/plans/${estimation_id}/kyc/residential-information`,
            completed: 'residential_basic_information_completion_status',
            category: 'basic_information',
        },
        {
            action: 'update-id-info',
            title: 'Identify Information',
            admin_title: `Customer's Identity Information`,
            description:
                'Your identify information is required to verify your identity.',
            icon: <Id />,
            link: '/consumer/account-setup/identity-information',
            admin_link: `/admin/plans/${estimation_id}/kyc/identity-information`,
            completed: 'residential_identity_information_completion_status',
            category: 'identity_information',
        },
        {
            action: 'update-financial-info',
            title: 'Financial Information',
            admin_title: `Customer's Financial Information`,
            description:
                "We need to run a credit check to be sure you're eligible for financing.",
            icon: <Briefcase />,
            link: CREATE_RESIDENTIAL_ACCOUNT.FINANCIAL_INFO,
            admin_link: `/admin/plans/${estimation_id}/kyc/residential-financial-information`,
            completed: 'residential_financial_information_completion_status',
            category: 'financial_information',
        },
    ]
}

export const createBusinessAccountSteps = estimation_id => {
    return [
        {
            action: 'update-business-info',
            title: 'Business Information',
            admin_title: `Customer's Business Information`,
            description:
                'Your business information is required to create your account.',
            icon: <User />,
            link: '/consumer/account-setup/business-information',
            admin_link: `/admin/plans/${estimation_id}/kyc/business-information`,
            completed: 'business_information_completion_status',
            category: 'business_information',
        },
        {
            action: 'update-verification-info',
            title: 'Business Verification',
            admin_title: `Customer's Business Verification`,
            description: 'We need to verify that your business exists.',
            icon: <Id />,
            link: '/consumer/account-setup/business-verification',
            admin_link: `/admin/plans/${estimation_id}/kyc/business-verification`,
            completed: 'business_verification_completion_status',
            category: 'business_verification',
        },
        {
            action: 'update-business-financial-info',
            title: 'Financial Information',
            admin_title: `Customer's Financial Information`,
            description:
                "We need to run a credit check to be sure you're eligible for financing.",
            icon: <Briefcase />,
            link: CREATE_BUSINESS_ACCOUNT.FINANCIAL_INFO,
            admin_link: `/admin/plans/${estimation_id}/kyc/business-financial-information`,
            completed: 'business_financial_information_completion_status',
            category: 'financial_information',
        },
    ]
}

export const createOutrightSaleSteps = (estimation, isAdminUser) => {
    const userType = estimation?.use_type
    const estimationId = estimation?.id

    return [
        {
            action: 'update-information',
            title: `${
                userType === 'RESIDENTIAL' ? 'Basic' : 'Business'
            } Information`,
            description: `Your ${
                userType === 'RESIDENTIAL' ? 'personal' : 'business'
            } information is required to process your order.`,
            admin_title: `Customer's ${
                userType === 'RESIDENTIAL' ? 'Basic' : 'Business'
            } Information`,
            icon: <User />,
            link: '/consumer/account-setup/outright-sale-information',
            admin_link: `/admin/outright-sale/${estimationId}/kyc/information`,
            completed:
                userType === 'RESIDENTIAL'
                    ? 'residential_basic_information_completion_status'
                    : 'business_information_completion_status',
            category:
                userType === 'RESIDENTIAL'
                    ? 'basic_information'
                    : 'business_information',
        },
        {
            action: 'view-delivery',
            title: 'System delivery',
            description: `Your order is currently being processed to be delivered to you.`,
            admin_title:
                "Customer's order is currently being processed to be delivered to them",
            icon: <Delivery />,
            completed: false,
            disabled: isAdminUser,
            notification: 'PROCESSING ORDER',
        },
    ]
}

export const createDynamicKycSteps = kycAppModules => {
    let steps = []

    const iconMapping = {
        personal_information: User,
        employment_information: Briefcase,
        identity_information: Id,
        financial_information: Briefcase,
    }

    const descriptionMapping = {
        personal_information:
            'Your personal information is required to create your account.',
        employment_information:
            'Your employment information is required to create your account.',
        identity_information:
            'Your identify information is required to verify your identity.',
        financial_information:
            "We need to run a credit check to be sure you're eligible for financing.",
    }
    const DefaultIcon = User

    if (kycAppModules) {
        const sortedKycModules = kycAppModules.sort((a, b) => a.order - b.order)
        sortedKycModules?.map(step => {
            const IconComponent = Object.keys(iconMapping).find(
                key => key === step?.key,
            )
                ? iconMapping[
                      Object.keys(iconMapping).find(key => key === step?.key)
                  ]
                : DefaultIcon

            steps.push({
                action: step?.title?.split(' ').join('-'),
                title: step?.title,
                description: descriptionMapping[step?.key],
                admin_title: `Customer's ${step?.title}`,
                icon: <IconComponent />,
                link: `/consumer/account-setup/${step?.id}`,
                completed: `${step?.key}_completion_status`,
                admin_link: `/admin/account-setup/${step?.id}`,
            })
        })
    }

    return steps
}

export const actionModalTitleMapping = useType => {
    const newUseType =
        useType === 'Residential use' ? 'business' : 'residential'
    return {
        'save-progress': 'Save your progress',
        'progress-saved': 'Redirecting to home page',
        'progress-saved-for-dep-consumer': 'Redirecting to home page',
        'switch-use-type-warning': `Switch to ${newUseType} use?`,
        'edit-info': 'Editing your Info',
        'submit-for-credit-decision': 'Submit your info',
        'initiate-dispute': 'Reason for disputing package',
        'dispute-submitted': 'Feedback collected',
        'payment-successful': 'Payment Successful',
        'submit-kyc': 'Getting started on SunFi',
        'admin-initiate-submit-kyc': 'Submit info',
        'admin-submitted-kyc-successfully': 'KYC submitted successfully',
    }
}

export const actionModalHeaderTextMapping = useType => {
    const newUseType =
        useType === 'Residential use' ? 'business' : 'residential'

    return {
        'save-progress': 'Save your progress? Please confirm',
        'progress-saved': 'Progress Saved, check your mail',
        'progress-saved-for-dep-consumer':
            'Thats fine! We have saved your progress',
        'switch-use-type-warning': `Details required for ${newUseType} use are different`,
        'edit-info': 'This action might affect your recommendations',
        'submit-for-credit-decision': 'Submit info and select payment plan',
        'initiate-dispute':
            'Your disputed information won’t change immediately',
        'dispute-submitted': 'We have saved your feedback',
        'payment-successful': (
            <p>
                Congratulations! &#127881;
                <br /> Your payment was successful!
            </p>
        ),
        'submit-kyc': 'Submit your KYC & Check your eligibility',
        'admin-initiate-submit-kyc': "Submit customer's info",
        'admin-submitted-kyc-successfully': 'KYC Submission was successful',
    }
}

export const actionModalSubtitleMapping = useType => {
    const newUseType =
        useType === 'Residential use' ? 'business' : 'residential'

    return {
        'save-progress':
            'You can save your progress and come back to continue anytime. We’ll also send an email on how you can continue',
        'progress-saved': `Progress saved! We've emailed you instructions to resume when you have the required information.`,
        'progress-saved-for-dep-consumer': `Progress saved! We've emailed you instructions to resume when you have the required information.`,
        'switch-use-type-warning': `Please, be aware that switching to a ${newUseType} use will require you to submit documents such as ${
            newUseType === 'business'
                ? 'CAC documents and 12-24 months of up-to-date, verified bank statements'
                : 'ID numbers and 6-12 months of up-to-date, verified bank statements'
        }`,
        'edit-info':
            'Please, be aware that editing your previously added info might affect your recommendations.',
        'submit-for-credit-decision':
            'Great work! The next step is to verify if you’re eligible of getting your solar system financed by SunFi.',
        'initiate-dispute':
            'Please be aware that you will not be able to change the disputed information until your provider is done with the re-estimation. You can also choose to reach out to your provider while this is pending.',
        'dispute-submitted':
            'We have notified your provider about your reasons for declining the project charter and they will reach out to you soon. Please go ahead and continue with your KYC to the right of your screen.',
        'payment-successful':
            "We'll reach out to you via email with the next steps regarding the delivery of your system.",
        'submit-kyc': `Great work! The next step is to click on submit in order
                        to verify your KYC details and check your eligibility.`,
        'admin-initiate-submit-kyc':
            'The next step is to verify if the customer is eligible to get their solar system financed by SunFi.',
        'admin-submitted-kyc-successfully':
            "Consumer's  KYC has been submitted successfully. We have notified the customer of this update.",
    }
}

export const actionModalIconMapping = {
    'save-progress': 'warning',
    'progress-saved': 'countdown-timer',
    'progress-saved-for-dep-consumer': 'mail-sent',
    'switch-use-type-warning': 'warning',
    'edit-info': 'warning',
    'submit-for-credit-decision': 'warning',
    'initiate-dispute': 'warning',
    'dispute-submitted': 'mail-sent',
    'payment-successful': 'success',
    'submit-kyc': 'warning',
    'admin-initiate-submit-kyc': 'warning',
    'admin-submitted-kyc-successfully': 'success',
}

export const actionModalButtonTextMapping = {
    'save-progress': 'Confirm',
    'progress-saved': 'Proceed to Log Out',
    'progress-saved-for-dep-consumer': 'Great, Thank you',
    'switch-use-type-warning': 'Confirm',
    'edit-info': 'Edit my Info',
    'submit-for-credit-decision': 'Confirm',
    'initiate-dispute': 'Continue',
    'dispute-submitted': 'Continue with KYC',
    'payment-successful': 'Done',
    'submit-kyc': 'Confirm',
    'admin-initiate-submit-kyc': 'Confirm',
    'admin-submitted-kyc-successfully': 'See credit review',
}

// estimation data
export const formatAccountOverviewData = (data, isAdminUser) => {
    let appliances_count =
        data?.consumer_form_questions?.appliances?.length ?? 0
    let repayment_months_count = data?.estimated_repayment_info?.tenure ?? 0
    let price_per_month =
        data?.estimated_repayment_info?.monthly_payment?.toLocaleString('US') ??
        0
    let provider_phone_number = data?.provider?.phone_number ?? 'N/A'
    let provider_location = data?.provider?.location ?? 'N/A'

    let values = {
        id: data?.id,
        user_id: data?.consumer_form_questions?.user_id,
        user_id_type: data?.consumer_form_questions?.user_id?.includes('@')
            ? 'email'
            : 'phone',
        system_name: data?.recommended_solution?.name ?? 'N/A',
        capacity:
            data?.recommended_solution?.energy_capacity?.value +
                ' ' +
                data?.recommended_solution?.energy_capacity
                    ?.unit_of_measurement ?? 'N/A',
        total_price:
            data?.recommended_solution?.total_cost?.toLocaleString('US') ?? 0,
        price_per_month: price_per_month?.toLocaleString('US') ?? 0,
        repayment_months_count: repayment_months_count ?? 0,
        appliances_count: appliances_count ?? 0,
        use_type: data?.customer_type,
        solution_id: data?.recommended_solution?.id,
        consumer_status: data?.consumer_status,
        appliances_from_provider: data?.appliances,
        has_solar_panel: data?.has_solar_panel,
        survey_type: data?.survey_type,
        payment_plan: data?.payment_plan,
        provider_name: data?.provider?.display_name ?? 'N/A',
        provider_initials:
            data?.provider?.display_name
                ?.split(' ')
                .map(word => word[0])
                .join('') || 'N/A',
        provider_created_at: data?.provider?.created_at,
        provider_phone_number: provider_phone_number.startsWith('+234')
            ? provider_phone_number.replace('+234', '0')
            : provider_phone_number,
        provider_state: provider_location?.split(', ').pop() || 'N/A',
        upfront_deposit_percentage: parseInt(data?.upfront_deposit) / 100,
        upfront_deposit_amount: data?.upfront_deposit_amount,
        onboardingType: data?.onboarding_type ?? '',
        paymentType: data?.payment_plan ?? '',
        backupHours: data?.recommended_solution?.average_backup_hours
            ? Math.trunc(data?.recommended_solution?.average_backup_hours)
            : 'N/A',
        approvalStatus: data?.approval_status,
        isOutrightSaleCustomer: data?.payment_plan === 'Outright Sale',
        isDynamicKycUser: data?.uses_dynamic_kyc,
        energy_info: [
            {
                title: 'Appliances added',
                value: `${appliances_count ?? 0} appliances`,
            },
            {
                title: 'Payment duration',
                value:
                    data?.payment_plan !== 'Subscription'
                        ? `${repayment_months_count ?? 0} months`
                        : 'Flexible',
            },
            {
                title: isAdminUser ? 'Pay/month' : 'Your pay/month',
                value: `${price_per_month ?? 0}`,
                isAmount: true,
            },
            {
                title: isAdminUser ? 'Location' : 'Your location',
                value:
                    data?.consumer_form_questions?.state_of_installation ??
                    'N/A',
            },
        ],
    }

    return values
}

export const formatEnergyInputs = data => {
    const gridHours = data?.consumer_form_questions?.gets_required_grid_hours
    let values = {
        gets_required_grid_hours: gridHours,
        generator_maintenance_expense:
            data?.consumer_form_questions?.generator_maintenance_expense,
        state_of_installation:
            data?.consumer_form_questions?.state_of_installation,
        feasible_monthly_payment:
            data?.consumer_form_questions?.feasible_monthly_payment,
        feasible_repayment_tenure:
            data?.consumer_form_questions?.feasible_repayment_tenure,
        solution_reason: data?.consumer_form_questions?.solution_reason,
        user_id: data?.consumer_form_questions?.user_id,
        stream_id: data?.consumer_form_questions?.user_id,
        can_mount_solar: data?.consumer_form_questions?.can_mount_solar,
        estimation_id: data?.id,
    }
    return values
}
export const useTypeMapping = {
    RESIDENTIAL: 'Residential use',
    BUSINESS: 'Business use',
}

export const formatEnergyNeeds = data => {
    return data?.map(appliance => ({
        id: uuidv4(),
        backup_hours: appliance.backup_hours,
        backup_hours_night: appliance.backup_hours_night,
        name: appliance.name,
        quantity: appliance.quantity,
        powerRating: appliance.power_rating,
        unitOfMeasurement: appliance.unit_of_measurement,
    }))
}

export const packageDrawerItems = [
    {
        title: 'Project charter',
        details: 'See details of your package here',
    },
    {
        title: 'Your selected solution',
        details: 'Take a look at your selected package',
    },
    {
        title: 'Your added appliances',
        details: 'View all appliances added by your provider',
    },
    {
        title: 'Provider details',
        details: 'Some details of your solution provider',
    },
]

export const providerDetailsList = estimation => {
    return [
        // TODO: align with design & BE on how to handle no. of installations
        {
            icon: building,
            alt: 'building',
            title: 'Over 100 installations',
        },
        {
            icon: mapPin,
            alt: 'map-pin',
            title: estimation?.provider_state,
        },
        {
            icon: phone,
            alt: 'phone',
            title: estimation?.provider_phone_number,
        },
    ]
}

export const disputeReasonsOptions = [
    'Appliances listed are not accurate',
    'The selected solution is not accurate',
    'I do not agree with the project charter',
    'Others',
]
