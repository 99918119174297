import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import '../gettingstarted.scss'
import { ReactComponent as Check } from 'src/assets/images/check-green.svg'
import { ReactComponent as Close } from 'src/assets/images/close-icon-white.svg'
import { shortenEmail } from '../../../utils/shortenEmail'
import Toast from 'src/components/Toast'
import ButtonComponent from 'src/components/Button'
import useVerify from '../hooks/useVerify'

const Verify = ({
    contact,
    otp,
    setOtp,
    otpError,
    inputs,
    showToastError,
    errorMessage,
    handleResendOtp,
    resent,
    setResent,
    heading = undefined,
    type,
    bvnVerify = false,
    disabled = false,
    handleBVNVerify = () => {},
}) => {
    const {
        requestCode,
        handleInputChange,
        handleKeyDown,
        handleInputPaste,
        activeOtpIndex,
        inputsRef,
        timerRef,
    } = useVerify(handleResendOtp, otp, setOtp)

    return (
        <div className={'GettingStartedWrapper'} data-testid="verify-wrapper">
            {resent && (
                <div
                    className={'GettingStartedResentMsg'}
                    data-testid="resent-message"
                >
                    <Check role={'presentation'} data-testid="resent-check" />
                    {type === 'bank' ? (
                        <p>Bank Otp resent</p>
                    ) : (
                        <p>
                            Verification code resent to your{' '}
                            {contact === 'email'
                                ? 'email address'
                                : 'phone number'}
                        </p>
                    )}
                    <Close
                        onClick={() => setResent(false)}
                        role={'presentation'}
                        style={{ cursor: 'pointer' }}
                        data-testid="close-resent"
                    />
                </div>
            )}

            {showToastError && (
                <Toast
                    messageType="error-secondary"
                    message={errorMessage}
                    closable={true}
                    autoHide={false}
                />
            )}
            <div className={'GettingStartedHeadingAndText noBottomMargin'}>
                {heading ?? (
                    <h3>
                        Verify your{' '}
                        {contact === 'email'
                            ? 'email address'
                            : type === 'bank'
                            ? 'bank'
                            : type === 'card'
                            ? 'card'
                            : 'phone number'}
                    </h3>
                )}
                {type === 'bank' ? (
                    <p>
                        A 4-digit otp has been sent to you, please enter the otp
                        below.
                    </p>
                ) : type === 'card' ? (
                    <p>
                        Please enter the last four (4) digits of your card
                        number below.
                    </p>
                ) : (
                    <p>
                        A 6-digit has been sent to your{' '}
                        {contact === 'email' ? 'email address' : 'phone number'}{' '}
                        <span>
                            (
                            {contact === 'email'
                                ? shortenEmail(inputs?.email)
                                : `+234******${inputs?.phone?.slice(-4)}`}
                            )
                        </span>
                        , please enter the the code below.
                    </p>
                )}
            </div>
            <div className={'GettingStartedOTPGroup'}>
                {otp?.map((_, idx) => (
                    <input
                        key={idx}
                        ref={idx === activeOtpIndex ? inputsRef : null}
                        type="number"
                        inputMode="numeric"
                        autoComplete="one-time-code"
                        pattern="\d{1}"
                        value={otp[idx]}
                        onChange={e => handleInputChange(e.target.value, idx)}
                        onKeyDown={e => handleKeyDown(e, idx)}
                        onPaste={e => handleInputPaste(e, idx)}
                        style={{
                            border: otpError ? '0.8px solid #DA1E28' : '',
                        }}
                        disabled={disabled}
                        data-testid={`otp-input-${idx}`}
                    />
                ))}
            </div>
            {otpError && (
                <p
                    className={'GettingStartedOTPErrorMsg'}
                    data-testid="otp-error-msg"
                >
                    Please enter a valid OTP
                </p>
            )}
            {type !== 'card' && (
                <CountdownTimer timerRef={timerRef} requestCode={requestCode} />
            )}
            {bvnVerify && (
                <div className="VerifyWithoutOTPStyle">
                    <p>Having issues receiving an OTP?</p>
                    <ButtonComponent
                        type="outline"
                        btnWidth="121px"
                        btnHeight="29px"
                        btnTextColorOutline="#004AAD"
                        btnBgColorOutline="#FFFFFFF"
                        fontSize="12px"
                        btnPadding="4px"
                        handleClick={() => handleBVNVerify()}
                        dataTestId="verify-without-otp-button"
                    >
                        Verify without OTP
                    </ButtonComponent>
                </div>
            )}
        </div>
    )
}

Verify.propTypes = {
    contact: PropTypes.string,
    otp: PropTypes.array,
    setOtp: PropTypes.func,
    otpError: PropTypes.bool,
    inputs: PropTypes.object,
    showToastError: PropTypes.bool,
    errorMessage: PropTypes.string,
    handleResendOtp: PropTypes.func,
    resent: PropTypes.bool,
    setResent: PropTypes.func,
    heading: PropTypes.string,
    type: PropTypes.string,
    bvnVerify: PropTypes.bool,
    disabled: PropTypes.bool,
    handleBVNVerify: PropTypes.func,
}

export default Verify

const CountdownTimer = props => {
    const [timer, setTimer] = useState(0)

    useEffect(() => {
        if (props.timerRef?.current) {
            props.timerRef.current.setTime = time => setTimer(time)
        }
    }, [props.timerRef])

    return (
        <h4 className={'GettingStartedResendOTPText'}>
            Didn&#39;t get the code?{'  '}
            <span
                onClick={() => props.requestCode?.()}
                role={'button'}
                style={{
                    cursor: timer !== 0 ? 'not-allowed' : 'pointer',
                    color: timer !== 0 ? '#B0B0B0' : '',
                }}
                data-testid="resend-code"
            >
                Resend code
            </span>{' '}
            in 00:
            {timer < 10 ? `0${timer}` : timer}
        </h4>
    )
}

CountdownTimer.propTypes = {
    requestCode: PropTypes.func,
    timerRef: PropTypes.object,
}
