import jwtDecode from 'jwt-decode'
import partnerTypes from './partnerTypes'
import { encrypt, decrypt } from './cryptography'

export const getToken = () => {
    const token = localStorage.getItem('sunfiToken')
    if (token) {
        return token
    }
    return null
}

export const setToken = token => {
    if (token) {
        localStorage.setItem('sunfiToken', token)
    } else {
        localStorage.removeItem('sunfiToken')
    }
}

export const removeToken = () => {
    localStorage.removeItem('sunfiToken')
}

export const isAuthenticated = () => {
    const token = getToken()
    if (token) {
        return true
    } else {
        return false
    }
}

export const decodeToken = token => {
    let decoded = {}
    try {
        decoded = jwtDecode(token)
    } catch (error) {
        // todo
        return false
    }
    return decoded
}

export const encodeUserInfo = data => {
    const encodedFirstName = encrypt(data?.data?.data?.user?.first_name)
    const encodedLastName = encrypt(data?.data?.data?.user?.last_name)
    const encodedEmail = encrypt(data?.data?.data?.user?.email)
    const encodedPhoto = encrypt(
        data?.data?.data?.user?.avatar_url || 'https://empty.png',
    )
    const encodePhoneNumber = encrypt(data?.data?.data?.user?.phone_number)
    const encodedIsUserService = encrypt(
        data?.data?.data?.user?.is_user_service?.toString(),
    )
    const encodedIsGrowthUser = encrypt(
        data?.data?.data?.user?.is_growth?.toString(),
    )
    const encodedIsDeveloperUser = encrypt(
        data?.data?.data?.user?.is_developer?.toString(),
    )
    const encodedIsFinanceUser = encrypt(
        data?.data?.data?.user?.is_finance?.toString(),
    )
    const encodedIsAdminWorkspaceUser = encrypt(
        data?.data?.data?.user?.is_admin_workspace_user?.toString(),
    )
    const encodedSystemAdmin = encrypt(
        data?.data?.data?.user?.is_system_admin?.toString(),
    )
    const encodedProviderId = encrypt(
        data?.data?.data?.user?.provider?.id || '',
    )
    const encodedId = encrypt(data?.data?.data?.user?.id)
    const encodedPartnerType = encrypt(
        partnerTypes[data?.data?.data?.user?.provider?.type?.identifier] || '',
    )
    const encodedCompanyName = encrypt(
        partnerTypes[data?.data?.data?.user?.provider?.type?.identifier] ===
            'ai'
            ? 'none'
            : data?.data?.data?.user?.provider?.company_name || '',
    )
    const encodedWorkspaceMember = encrypt(
        data?.data?.data?.user?.provider?.member?.toString() || '',
    )
    const encodedGlobalContributor = encrypt(
        data?.data?.data?.user?.provider?.is_gl_contributor?.toString() || '',
    )
    const encodedDEPConsumer = encrypt(
        data?.data?.data?.is_dep_customer?.toString() || '',
    )
    const encodedSubscriptionProvider = encrypt(
        data?.data?.data?.user?.provider?.is_subscription_provider,
    )

    const encodedClusterLogo = encrypt(data?.data?.data?.cluster_logo)
    const encodedIsFirstTimeLogin = encrypt(
        data?.data?.data?.is_first_time_login,
    )
    const encodedClusterProfileId = encrypt(
        data?.data?.data?.cluster_profile_id,
    )

    const encodedClusterName = encrypt(data?.data?.data?.cluster_name)

    const userObject = {
        id: encodedId,
        isSystemAdmin: encodedSystemAdmin,
        firstName: encodedFirstName,
        lastName: encodedLastName,
        email: encodedEmail,
        phoneNumber: encodePhoneNumber,
        profilePic: encodedPhoto,
        partnerType: encodedPartnerType,
        companyName: encodedCompanyName,
        workspaceMember: encodedWorkspaceMember,
        globalContributor: encodedGlobalContributor,
        providerId: encodedProviderId,
        isUserService: encodedIsUserService,
        isAdminWorkspaceUser: encodedIsAdminWorkspaceUser,
        isGrowthUser: encodedIsGrowthUser,
        isDeveloperUser: encodedIsDeveloperUser,
        isFinanceUser: encodedIsFinanceUser,
        isDEPConsumer: encodedDEPConsumer,
        isSubscriptionProvider: encodedSubscriptionProvider,
        ...(process.env.REACT_APP_FLAG_SHOW_REPRESENTATIVE_WORKSPACE && {
            clusterLogo: encodedClusterLogo,
        }),
        ...(process.env.REACT_APP_FLAG_SHOW_REPRESENTATIVE_WORKSPACE && {
            isFirstTimeLogin: encodedIsFirstTimeLogin,
        }),
        ...(process.env.REACT_APP_FLAG_SHOW_REPRESENTATIVE_WORKSPACE && {
            clusterProfileId: encodedClusterProfileId,
        }),
        ...(process.env.REACT_APP_FLAG_SHOW_REPRESENTATIVE_WORKSPACE && {
            clusterName: encodedClusterName,
        }),
    }
    localStorage.setItem('sunfiUserInfo', JSON.stringify(userObject))
}

export const decodeUserInfo = () => {
    const token = getToken()
    try {
        if (token) {
            const getUserInfo = JSON.parse(
                localStorage.getItem('sunfiUserInfo'),
            )
            const decodedEmail = decrypt(getUserInfo?.['email'])
            const decodedPhoneNumber = decrypt(getUserInfo?.['phoneNumber'])

            const decodedId = decrypt(getUserInfo?.['id'])
            const decodedFirstName = decrypt(getUserInfo?.['firstName'])
            const decodedLastName = decrypt(getUserInfo?.['lastName'])
            const decodedCompanyName = decrypt(getUserInfo?.['companyName'])
            const decodedPartnerType = decrypt(getUserInfo?.['partnerType'])
            const decodedProfilePic = decrypt(getUserInfo?.['profilePic'])
            const decodedWorkspaceMember = decrypt(
                getUserInfo?.['workspaceMember'],
            )
            const decodedGlobalContributor = decrypt(
                getUserInfo?.['globalContributor'],
            )
            const decodedProviderId = decrypt(getUserInfo?.['providerId'])
            const decodedIsUserService = decrypt(getUserInfo?.['isUserService'])
            const decodedIsAdminWorkspaceUser = decrypt(
                getUserInfo?.['isAdminWorkspaceUser'],
            )
            const decodedIsSystemAdmin = decrypt(getUserInfo?.['isSystemAdmin'])
            const decodedIsGrowthUser = decrypt(getUserInfo?.['isGrowthUser'])
            const decodedIsDeveloperUser = decrypt(
                getUserInfo?.['isDeveloperUser'],
            )
            const decodedIsFinanceUser = decrypt(getUserInfo?.['isFinanceUser'])
            const decodedIsDEPConsumer = decrypt(getUserInfo?.['isDEPConsumer'])
            const decodedIsSubscriptionProvider = decrypt(
                getUserInfo?.['isSubscriptionProvider'],
            )
            const decodedClusterLogo = decrypt(getUserInfo?.['clusterLogo'])
            const decodedIsFirstTimeLogin = decrypt(
                getUserInfo?.['isFirstTimeLogin'],
            )
            const decodedClusterProfileId = decrypt(
                getUserInfo?.['clusterProfileId'],
            )
            const decodedClusterName = decrypt(getUserInfo?.['clusterName'])

            const decodedInfo = {
                id: decodedId,
                firstName: decodedFirstName,
                lastName: decodedLastName,
                email: decodedEmail,
                phoneNumber: decodedPhoneNumber,
                companyName: decodedCompanyName?.includes('=')
                    ? 'none'
                    : decodedCompanyName,
                partnerType: decodedPartnerType,
                profilePic: decodedProfilePic,
                workspaceMember: decodedWorkspaceMember,
                globalContributor: decodedGlobalContributor,
                providerId: decodedProviderId,
                isUserService: decodedIsUserService,
                isAdminWorkspaceUser: decodedIsAdminWorkspaceUser,
                isSystemAdmin: decodedIsSystemAdmin,
                isGrowthUser: decodedIsGrowthUser,
                isDeveloperUser: decodedIsDeveloperUser,
                isFinanceUser: decodedIsFinanceUser,
                isDEPConsumer: decodedIsDEPConsumer,
                isSubscriptionProvider: decodedIsSubscriptionProvider,
                ...(process.env
                    .REACT_APP_FLAG_SHOW_REPRESENTATIVE_WORKSPACE && {
                    clusterLogo: decodedClusterLogo,
                }),
                ...(process.env
                    .REACT_APP_FLAG_SHOW_REPRESENTATIVE_WORKSPACE && {
                    isFirstTimeLogin: decodedIsFirstTimeLogin,
                }),
                ...(process.env
                    .REACT_APP_FLAG_SHOW_REPRESENTATIVE_WORKSPACE && {
                    clusterProfileId: decodedClusterProfileId,
                }),
                ...(process.env
                    .REACT_APP_FLAG_SHOW_REPRESENTATIVE_WORKSPACE && {
                    clusterName: decodedClusterName,
                }),
            }

            return decodedInfo
        } else {
            return {}
        }
    } catch (error) {
        logsOutUser()
    }
}

export const updateUserInfo = inputs => {
    let userInfo = decodeUserInfo()
    const inputInfo = {
        firstName: inputs.first_name,
        lastName: inputs.last_name,
        email: inputs.email,
        phoneNumber: inputs.phone_number,
        profilePic: inputs?.avatar_url,
        companyName:
            partnerTypes[inputs?.provider?.type?.identifier] === 'ai'
                ? 'none'
                : inputs?.provider?.company_name,
        id: inputs.id,
    }
    userInfo = { ...userInfo, ...inputInfo }
    const userObject = {
        id: encrypt(userInfo?.id),
        firstName: encrypt(userInfo?.firstName),
        lastName: encrypt(userInfo?.lastName),
        email: encrypt(userInfo?.email),
        phoneNumber: encrypt(userInfo?.phoneNumber),
        profilePic: encrypt(userInfo?.profilePic),
        partnerType: encrypt(userInfo?.partnerType),
        companyName: encrypt(userInfo?.companyName),
        workspaceMember: encrypt(userInfo?.workspaceMember),
        providerId: encrypt(userInfo?.providerId),
        isUserService: encrypt(userInfo?.isUserService),
        isAdminWorkspaceUser: encrypt(userInfo?.isAdminWorkspaceUser),
        isSystemAdmin: encrypt(userInfo?.isSystemAdmin),
        isGrowthUser: encrypt(userInfo?.isGrowthUser),
        isDeveloperUser: encrypt(userInfo?.isDeveloperUser),
        isFinanceUser: encrypt(userInfo?.isFinanceUser),
    }

    localStorage.setItem('sunfiUserInfo', JSON.stringify(userObject))
}

export const decodeUserNextStage = () => {
    const nextStage = localStorage.getItem('sunfiUserStage')
    if (nextStage) {
        return decrypt(nextStage)
    } else {
        return false
    }
}

export const removeTokensOnLogout = () => {
    localStorage.removeItem('sunfiToken')
    localStorage.removeItem('addBrandNotificationDisplayed')
}

export const logsOutUser = (nextPage = '', redirectPath = '') => {
    if (redirectPath) {
        removeTokensOnLogout()
        window.location.href = redirectPath
        return
    }

    const processType = localStorage.getItem('processType')
    const isSSEConsumer = localStorage.getItem('isSSEConsumer')
    const isClusterRepWorkspace =
        localStorage.getItem('isClusterRep') && !nextPage.includes('/admin')
    const isComingFromConsumerWorkspace =
        nextPage.includes('consumer') && !nextPage.includes('/admin')
    let redirectUrl =
        process.env.REACT_APP_REDIRECT_TO_MAINTENANCE_PAGE === 'true'
            ? '/'
            : nextPage === ''
            ? '/login'
            : `/login?next=${nextPage}`
    if (isComingFromConsumerWorkspace) {
        redirectUrl = `/consumer/login`
    }
    if (isClusterRepWorkspace) {
        redirectUrl === '/rep/login'
    }
    if (
        processType === 'repaymentSchedule' ||
        processType === 'updateApplication'
    ) {
        redirectUrl = '/consumer/welcome'
    } else if (isSSEConsumer) {
        redirectUrl = '/'
    }

    removeTokensOnLogout()
    window.location.href = redirectUrl
}
