import AccountSetupFooter from 'src/components/ConsumerFooter/AccountSetupFooter'
import styles from '../_components/Layout/layout.module.scss'
import s from './styles.module.scss'
import Header from './components/Header'
import Description from '../_components/Description'
import SelectedSolutionCard from './components/SelectedSolutionCard'
import Form from './components/Form'
import useOutrightSaleInformation from './hooks/useOutrightSaleInformation'
import SeoComponent from 'src/components/Seo'
import ActionModalV2 from 'src/components/ActionModalV2'
import { actionModalValues } from './utils'
import VerifyContactModal from './components/VerifyContactModal'
import FormSkeleton from '../_components/FormSkeleton'
import PayStack from '../../CreditDecision/components/PayStack'
import AddPackageDrawer from 'src/pages/Admin/Profiles/AddPackage/components/AddPackageDrawer'
import { errorHandler } from 'src/utils/errorHandler'
import ActionModal from 'src/components/ActionModal'

const OutrightSaleInformation = () => {
    const { LayoutWrapper, MainWrapper } = styles
    const {
        inputs,
        errors,
        handleOnBlur,
        handleChange,
        handleSelectChange,
        handleSelectOnBlur,
        sameAddressOptions,
        handleSameAddressOptionChange,
        actionModalOpen,
        action,
        closeActionModal,
        actionModalHandler,
        goToSSEHome,
        actionModalSecondaryHandler,
        userType,
        showVerifyContactModal,
        initiateContactVerification,
        closeVerifyContactModal,
        contactTypeToVerify,
        verifyContactsModalInputs,
        otp,
        setOtp,
        otpError,
        handleResendOtp,
        verifyContactToast,
        initiateVerificationResent,
        initiateVerificationSetResent,
        handleOtpSubmit,
        initiateEmailVerificationLoading,
        initiatePhoneVerificationLoading,
        pageLoading,
        offer,
        handleChangePackage,
        seePackageInfo,
        saveLoading,
        submitLoading,
        actionModalToast,
        showPayStack,
        reloadPage,
        paystackLoading,
        handlePaystackLoading,
        handleInitializePaymentError,
        callInitializePayment,
        verifyLoading,
        setVerifyLoading,
        handleShowPaystackModal,
        onPaymentSuccess,
        hasPaidOutrightly,
        handleSaveInfo,
        handleSubmitInfo,
        isAdminUser,
        selectedPackage,
        showAddPackageDrawer,
        toggleAddPackageDrawer,
        handleUpdatePackages,
        showActionModal,
        setShowActionModal,
        changePackage,
        handleChangePackageSuccess,
    } = useOutrightSaleInformation()

    const {
        actionModalTitle,
        actionModalHeaderText,
        actionModalSubtitle,
        actionModalActionType,
        actionModalPrimaryBtnText,
        actionModalFooterLeftContent,
        actionModalSecondaryBtnText,
    } = actionModalValues(action)

    return (
        <>
            <SeoComponent
                title={`KYC - ${
                    userType === 'RESIDENTIAL' ? 'Personal' : 'Business'
                } Information | SunFi | Simplifying and Scaling Clean Energy`}
            />

            {/* modals */}
            <ActionModalV2
                actionModalOpen={actionModalOpen}
                title={actionModalTitle}
                headerText={actionModalHeaderText}
                subTitle={actionModalSubtitle}
                actionType={actionModalActionType}
                primaryBtnText={actionModalPrimaryBtnText}
                handlePrimaryBtnClick={actionModalHandler}
                closable
                onCancel={() => closeActionModal()}
                closeModal={() => closeActionModal()}
                noBackLink
                countdownTimerSeconds={10}
                onTimeEnd={
                    action === 'progress-saved' ? () => goToSSEHome() : () => {}
                }
                footerLeftContent={actionModalFooterLeftContent}
                secondaryBtnText={actionModalSecondaryBtnText}
                handleSecondaryBtnClick={actionModalSecondaryHandler}
                primaryBtnLoading={saveLoading}
                toastError={actionModalToast?.showToast}
                errorMessage={actionModalToast?.toastMessage}
                errorMessageType={actionModalToast?.messageType}
            />

            <ActionModal
                actionModalOpen={showActionModal?.open}
                headerText={
                    showActionModal?.action === 'success'
                        ? 'Consumer package changed'
                        : 'You’re about to change the package'
                }
                subTitle={
                    showActionModal?.action === 'success' ? (
                        <>
                            You have successfully changed the consumer’s
                            selected package from{' '}
                            <span
                                style={{
                                    color: '#233862',
                                    fontFamily: 'SF-Pro-Display-Medium',
                                }}
                            >
                                {offer?.systemName}
                            </span>{' '}
                            to{' '}
                            <span
                                style={{
                                    color: '#233862',
                                    fontFamily: 'SF-Pro-Display-Medium',
                                }}
                            >
                                {selectedPackage[0]?.name}
                            </span>
                            .
                        </>
                    ) : (
                        <>
                            Kindly confirm you want to change the selected
                            package of this consumer from{' '}
                            <span
                                style={{
                                    color: '#233862',
                                    fontFamily: 'SF-Pro-Display-Medium',
                                }}
                            >
                                {offer?.systemName}
                            </span>{' '}
                            to{' '}
                            <span
                                style={{
                                    color: '#233862',
                                    fontFamily: 'SF-Pro-Display-Medium',
                                }}
                            >
                                {selectedPackage[0]?.name}
                            </span>
                            .
                        </>
                    )
                }
                actionType={
                    showActionModal?.action === 'success'
                        ? 'success'
                        : 'warning'
                }
                noCancelBtn={showActionModal?.action === 'success'}
                actionText={
                    showActionModal?.action === 'success'
                        ? 'Okay, Got it'
                        : 'Confim'
                }
                cancelText={'Go Back'}
                actionHandler={handleChangePackageSuccess}
                closable={false}
                closeModal={() =>
                    setShowActionModal({ open: false, action: 'success' })
                }
                toastError={changePackage.isError}
                errorMessage={errorHandler(
                    changePackage.error?.response?.data ||
                        changePackage.error?.message,
                )}
                loading={changePackage.isLoading}
            />

            <VerifyContactModal
                showModal={showVerifyContactModal}
                onCancel={closeVerifyContactModal}
                contact={contactTypeToVerify}
                inputs={verifyContactsModalInputs}
                otp={otp}
                setOtp={setOtp}
                otpError={otpError}
                handleResendOtp={handleResendOtp}
                verifyContactToast={verifyContactToast}
                resent={initiateVerificationResent}
                setResent={initiateVerificationSetResent}
                handleOtpSubmit={handleOtpSubmit}
            />

            {showPayStack && (
                <PayStack
                    estimationId={offer?.offerId}
                    reloadPage={reloadPage}
                    email={inputs?.email}
                    handleLoading={() => handlePaystackLoading()}
                    handleError={handleInitializePaymentError}
                    type="outright-sale-customer"
                    callInitializePayment={callInitializePayment}
                    setVerifyLoading={setVerifyLoading}
                    openCardDetailsModal={handleShowPaystackModal}
                    onSuccess={onPaymentSuccess}
                    v2
                />
            )}

            {isAdminUser && (
                <AddPackageDrawer
                    selectedPackages={selectedPackage}
                    showAddPackageModal={showAddPackageDrawer}
                    setShowAddPackageModal={toggleAddPackageDrawer}
                    handleAddPackagesClick={handleUpdatePackages}
                    isOnePackage
                />
            )}

            {/* page */}

            <div className={LayoutWrapper}>
                <Header
                    userType={userType}
                    handleSaveInfo={handleSaveInfo}
                    handleSubmitInfo={handleSubmitInfo}
                    submitLoading={submitLoading}
                    paystackLoading={paystackLoading}
                    verifyLoading={verifyLoading}
                    hasPaidOutrightly={hasPaidOutrightly}
                    pageLoading={pageLoading}
                />
                <main className={MainWrapper}>
                    <Description
                        title={`Add your ${
                            userType === 'RESIDENTIAL' ? 'personal' : 'business'
                        } information`}
                        subTitle={`To create your account and confirm your order, we need you to provide your ${
                            userType === 'RESIDENTIAL' ? 'personal' : 'business'
                        } information`}
                        step={'outright-sale-info'}
                        durationInMins={5}
                    />
                    <div className={s.rightLayoutWrapper}>
                        <div
                            className={s.rightLayoutContent}
                            style={{ width: pageLoading ? '60%' : '' }}
                        >
                            {!pageLoading && (
                                <SelectedSolutionCard
                                    offer={offer}
                                    handleChangePackage={handleChangePackage}
                                    seePackageInfo={seePackageInfo}
                                    hasPaidOutrightly={hasPaidOutrightly}
                                />
                            )}
                            {pageLoading ? (
                                <FormSkeleton style={{ padding: 20 }} />
                            ) : (
                                <Form
                                    inputs={inputs}
                                    errors={errors}
                                    handleOnBlur={handleOnBlur}
                                    handleChange={handleChange}
                                    handleSelectChange={handleSelectChange}
                                    handleSelectOnBlur={handleSelectOnBlur}
                                    sameAddressOptions={sameAddressOptions}
                                    handleSameAddressOptionChange={
                                        handleSameAddressOptionChange
                                    }
                                    initiateContactVerification={
                                        initiateContactVerification
                                    }
                                    initiateEmailVerificationLoading={
                                        initiateEmailVerificationLoading
                                    }
                                    initiatePhoneVerificationLoading={
                                        initiatePhoneVerificationLoading
                                    }
                                    userType={userType}
                                    hasPaidOutrightly={hasPaidOutrightly}
                                />
                            )}
                        </div>
                    </div>
                </main>
                <AccountSetupFooter />
            </div>
        </>
    )
}

export default OutrightSaleInformation
