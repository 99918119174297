import { currencyFormatter } from 'src/utils/currencyFormatter'
import { convertToSentenceCase, replaceHtmlTags } from 'src/utils/formatting'
import { packagePaymentTypes } from './components/RecommendedPackage/utils'

export const formatPackageData = packages => {
    return packages.map(singlePackage => ({
        id: singlePackage.id,
        name: convertToSentenceCase(singlePackage.name),
        description: replaceHtmlTags(singlePackage.description),
        amount:
            '₦' +
            currencyFormatter(singlePackage.total || singlePackage.amount || 0),
        energy_capacity: singlePackage.energy_capacity,
        appliances: singlePackage.appliances || [],
        subscription_supported: singlePackage?.subscription_supported,
        lease_to_own_supported: singlePackage?.lease_to_own_supported,
        outright_sale_supported: singlePackage?.outright_sale_supported,
    }))
}

export const paymentTypeOptions = [
    {
        label: 'All',
        value: 'all',
    },
    {
        label: 'Lease to own',
        value: 'Lease to Own',
    },
    {
        label: 'Outright sale',
        value: 'Outright Sale',
    },
    {
        label: 'Subscription',
        value: 'Subscription',
    },
]

export const formatPaymentTypesOnChange = (values, data) => {
    const lastIndex = values.length - 1
    let update = [...values]
    if (lastIndex > 0) {
        let allOptionsArr = packagePaymentTypes(
            data?.lease_to_own_supported,
            data?.subscription_supported,
            data?.outright_sale_supported,
            true,
        ).filter(
            option => option.value !== 'all' && !update.includes(option.value),
        )

        if (values[lastIndex] === 'all' || !allOptionsArr.length) {
            update = ['all']
        } else {
            update = values.filter(val => val !== 'all')
        }
    }

    return update
}

export const formatPaymentTypesOptionsForApi = (values, data) => {
    const options = packagePaymentTypes(
        data?.lease_to_own_supported,
        data?.subscription_supported,
        data?.outright_sale_supported,
        true,
    )
        .map(option => option.value)
        .filter(option => option !== 'all')

    if (values.includes('all')) {
        return options
    }

    return values
}

export const formatPaymentTypesOptionsForInput = (values, data) => {
    const options = packagePaymentTypes(
        data?.lease_to_own_supported,
        data?.subscription_supported,
        data?.outright_sale_supported,
        true,
    )
        .map(option => option.value)
        .filter(option => option !== 'all')

    let isAll = true

    for (const option of options) {
        if (!values.includes(option)) {
            isAll = false
            break
        }
    }

    if (isAll) {
        return ['all']
    }

    return values
}
