import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useUploadToS3 } from 'src/utils/Hooks/useUploadToS3'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import { errorHandler } from 'src/utils/errorHandler'
import { useDispatch, useSelector } from 'react-redux'
import {
    updateConsumerProfile,
    updatedConsumerProfile,
} from 'src/redux/reducers/admin/profiles/create-profile'
import { useParams } from 'react-router-dom/cjs/react-router-dom'
import { useGetProfile } from '../hooks/useGetProfile'
import { useUpdateProfile } from '../hooks/useUpdateProfile'

export const useClusterBanner = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const consumer_profile_data = useSelector(updatedConsumerProfile)
    const { profile_id } = useParams()
    const { errorAlert } = useCustomToast()
    const [width, setWidth] = useState(5)
    const [inputs, setInputs] = useState({
        image_url: consumer_profile_data?.image_url || '',
    })
    const [errors, setErrors] = useState({})
    const [showActionModal, setShowActionModal] = useState(false)

    const {
        data: staleProfile,
        isLoadingProfile,
        refetchProfile,
    } = useGetProfile({
        profileId: profile_id,
        onSuccess: data => {
            setInputs({
                image_url: data?.meta_data?.image_url,
            })
        },
    })

    const { uploadToS3Loading, uploadToS3Mutate } = useUploadToS3({
        onSuccess: data => {
            console.log({ data })
            setInputs({ image_url: data })
            setErrors({ image_url: '' })
        },
        onError: e => {
            errorAlert(errorHandler(e) || 'Something went wrong')
        },
    })

    useEffect(() => {
        const handleSlider = () => {
            if (uploadToS3Loading && width === 97.5) {
                setWidth(prev => prev - 50)
            } else if (uploadToS3Loading && width < 100) {
                setWidth(prev => prev + 2.5)
            } else if (!uploadToS3Loading && width > 5) {
                setWidth(100)
            }
        }
        const sliderWidth = setTimeout(handleSlider, 500)
        return () => clearTimeout(sliderWidth)
    }, [width, uploadToS3Loading])

    const beforeUpload = file => {
        const isLt2M = file.size / 1000 / 1000 < 5

        if (!isLt2M) {
            setErrors({
                image_url: 'Please upload a png file no more than 5mb',
            })
        } else if (file.type !== 'image/png') {
            setErrors({
                image_url: 'Please upload a png file',
            })
        }
    }

    const props = {
        name: 'file',
        onChange({ fileList }) {
            uploadToS3Mutate({
                category: 'ENERGY_PROFILE',
                file: fileList[0],
            })
        },
    }

    console.log({ inputs })

    const { handleUpdateProfile, isLoading: updateProfileLoading } =
        useUpdateProfile({
            data: {
                image_url: inputs?.image_url,
                id: profile_id,
            },
            onSuccess: () => {
                setShowActionModal(true)
            },
        })

    const handleNext = () => {
        if (inputs.image_url.length === 0) {
            setErrors({ image_url: 'Please upload a png file' })
        } else {
            if (profile_id) {
                const noUpdates = () => {
                    return (
                        staleProfile?.data?.data?.meta_data?.image_url ===
                        inputs?.image_url
                    )
                }

                if (noUpdates()) {
                    history.push(`/admin/consumer-profiles/${profile_id}`)
                } else {
                    handleUpdateProfile()
                }
            } else {
                dispatch(updateConsumerProfile({ image_url: inputs.image_url }))
                history.push({
                    pathname: `/admin/consumer-profiles/review/public`,
                })
            }
        }
    }

    return {
        inputs,
        setInputs,
        errors,
        setErrors,
        beforeUpload,
        props,
        handleNext,
        uploadToS3Loading,
        width,
        updateProfileLoading,
        showActionModal,
        setShowActionModal,
        isLoadingProfile,
        profile_id,
        refetchProfile,
    }
}
