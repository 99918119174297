import PropTypes from 'prop-types'
import { useState } from 'react'
import { availablePaymentPlans } from '../data'
import Button, { ConsumerButton } from 'src/components/Button'
import morePlans from 'src/assets/images/more-plans.svg'
import morePlansWhite from 'src/assets/images/more-plans-white.svg'
import '../viewsystem.scss'
import RepaymentOptionsModal from './RepaymentOptionsModal'
import { isMobile } from 'src/utils/mediaQueries'
import { numberToWords } from 'src/utils/numbertToWords'
import NairaSymbol from 'src/components/NairaSymbol'
import ChatCTA from 'src/components/ChatCTA'
import infoIcon from 'src/assets/images/warning-info-thick.svg'
import CustomToolTip from 'src/components/CustomToolTip'
import { removeCommaFromNum } from 'src/utils/NumberFormatter'

const RepaymentOptions = ({
    paymentPlans,
    totalCost,
    paymentModel,
    monthPayment,
    preferred_payment_tenure,
    setShowExploreModal,
    supportedPaymentPlans,
}) => {
    const [showModal, setShowModal] = useState(false)

    const firstInsuranceCost =
        paymentPlans?.[0]?.investment_meta_data?.monthly_insurance_percentage

    return (
        <>
            <RepaymentOptionsModal
                showModal={showModal}
                onCancel={() => setShowModal(false)}
                paymentPlans={paymentPlans}
                totalCost={totalCost}
                preferred_payment_tenure={preferred_payment_tenure}
            />
            <div
                className={'ViewSystemTabsContentPayment'}
                id={'VS-PaymentBreakdown'}
            >
                <div className={'ViewSystemTabsPaymentTop'}>
                    <div className={'ViewSystemTabsPaymentText'}>
                        {paymentModel === 'Lease to Own' && (
                            <h4>Flexible finance options</h4>
                        )}
                        <h3>
                            {paymentModel === 'Outright Sale'
                                ? 'Buy outrightly'
                                : paymentModel === 'Lease to Own'
                                ? `${paymentPlans?.length} payment plan${
                                      paymentPlans?.length !== 1 ? 's' : ''
                                  }`
                                : 'Subscription plan'}
                        </h3>
                        <p>
                            {paymentModel === 'Outright Sale'
                                ? `This plan lets you pay the full amount for your system at once, with no monthly payments or interest.`
                                : paymentModel === 'Lease to Own'
                                ? `You have been offered ${numberToWords(
                                      paymentPlans?.length,
                                  )} Lease-to-Own plans. These plans require an initial deposit and specific monthly payments to purchase the product. It's important to use only essential energy appliances and ensure adequate charging of the system.`
                                : 'This plan requires you to make a monthly payment for your subscription to provide you with Financial ease and flexibility. You’ll be able to keep track of your payments through the SunF web-app.'}
                        </p>
                        {process.env.REACT_APP_FLAG_SHOW_OUTRIGHT_SALE ===
                            'true' &&
                            supportedPaymentPlans?.length > 1 && (
                                <div className="ViewSystemTabsPaymentTopExtraContent">
                                    <p>
                                        {paymentModel === 'Outright Sale'
                                            ? `Want to subscribe or pay in installments to own this system instead?`
                                            : paymentModel === 'Lease to Own'
                                            ? `Want to rent or own this system instead?`
                                            : `Want to lease or own this system instead?`}
                                    </p>
                                    <Button
                                        btnBgColor={'#004AAD'}
                                        btnTextColor={'#FFFFFF'}
                                        btnWidth={'190px'}
                                        btnHeight={'29px'}
                                        fontSize="12px"
                                        handleClick={() =>
                                            setShowExploreModal(true)
                                        }
                                    >
                                        Explore other payment options
                                    </Button>
                                </div>
                            )}
                    </div>
                    <div className={'ViewSystemTabsPaymentTop_Right'}>
                        {process.env
                            .REACT_APP_INSURANCE_INCLUSIVE_PAYMENT_PLANS_ENABLED ===
                            'true' &&
                            firstInsuranceCost && (
                                <div
                                    className={
                                        'ViewSystemTabsPayment_Insurance'
                                    }
                                >
                                    <p>
                                        An insurance fee is being added to each
                                        plan
                                    </p>
                                    <CustomToolTip
                                        text={
                                            'We have added an insurance fee to each plan listed under this package.'
                                        }
                                        toolTipPopupWidth={174}
                                        toolTipMarginLeft={-100}
                                        toolTipPadding={'9px 10px 25px 10px'}
                                    >
                                        <div
                                            className={
                                                'ViewSystemTabsPayment_Insurance_Num'
                                            }
                                        >
                                            <span>{firstInsuranceCost}</span>
                                            <img
                                                src={infoIcon}
                                                alt={'info icon'}
                                            />
                                        </div>
                                    </CustomToolTip>
                                </div>
                            )}
                        {paymentModel === 'Outright Sale' ? (
                            <div style={{ width: '100%' }}>
                                <div className={'ViewSystemL20PlanCard'}>
                                    <div
                                        className={
                                            'ViewSystemL20PlanCardRowOne'
                                        }
                                    >
                                        <h4>
                                            <span>One-time </span>
                                            payments of
                                        </h4>
                                        <h5>
                                            <span>
                                                <NairaSymbol />
                                                {totalCost?.toLocaleString(
                                                    'en-US',
                                                    {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    },
                                                )}
                                            </span>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        ) : paymentModel === 'Lease to Own' ? (
                            <div style={{ width: '100%' }}>
                                {availablePaymentPlans(
                                    paymentPlans,
                                    preferred_payment_tenure,
                                    totalCost,
                                )
                                    ?.slice(0, 2)
                                    .map((item, i) => (
                                        <div
                                            key={i}
                                            className={'ViewSystemL20PlanCard'}
                                        >
                                            <div
                                                className={
                                                    'ViewSystemL20PlanCardRowOne'
                                                }
                                            >
                                                <h4>
                                                    <span>
                                                        {item?.count} monthly
                                                    </span>
                                                    {isMobile ? <br /> : ' '}
                                                    payments of
                                                </h4>
                                                <h5>
                                                    <span>
                                                        <NairaSymbol />
                                                        {item?.monthly_payment?.toLocaleString(
                                                            'en-US',
                                                        )}{' '}
                                                    </span>
                                                    / Month
                                                </h5>
                                            </div>
                                            <div
                                                className={
                                                    'ViewSystemL20PlanCardRowTwo'
                                                }
                                            >
                                                <span>Upfront payment of</span>
                                                <p>
                                                    <NairaSymbol />
                                                    {item?.initial_deposit?.toLocaleString(
                                                        'en-US',
                                                    )}
                                                </p>
                                            </div>
                                            {process.env
                                                .REACT_APP_INSURANCE_INCLUSIVE_PAYMENT_PLANS_ENABLED ===
                                                'true' &&
                                                item?.investment_meta_data
                                                    ?.monthly_insurance_amount && (
                                                    <div
                                                        className={
                                                            'ViewSystemL20PlanCard_InsuranceRow'
                                                        }
                                                    >
                                                        <span>
                                                            Insurance amount of
                                                        </span>
                                                        <p>
                                                            <NairaSymbol />
                                                            {item?.investment_meta_data?.monthly_insurance_amount?.toLocaleString(
                                                                'en-US',
                                                            )}
                                                        </p>
                                                    </div>
                                                )}
                                            <div
                                                className={
                                                    'ViewSystemL20PlanCard_TotalRow'
                                                }
                                            >
                                                <span>Total payment of</span>
                                                <p>
                                                    <NairaSymbol />
                                                    {item?.total_payment?.toLocaleString(
                                                        'en-US',
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        ) : (
                            <div className={'ViewSystemSubscriptionPlanCard'}>
                                <div
                                    className={
                                        'ViewSystemSubscriptionPlanCardRowOne'
                                    }
                                >
                                    <h4>
                                        <span>Monthly</span>
                                        {isMobile ? <br /> : ' '}
                                        payments of
                                    </h4>
                                    <h5>
                                        <span>
                                            <NairaSymbol />
                                            {monthPayment?.toLocaleString(
                                                'en-US',
                                            )}{' '}
                                        </span>
                                        / Month
                                    </h5>
                                </div>
                                {Number(
                                    removeCommaFromNum(
                                        paymentPlans?.[paymentPlans?.length - 1]
                                            ?.initial_deposit,
                                    ),
                                ) > 0 ? (
                                    <div
                                        className={
                                            'ViewSystemL20PlanCardRowTwo'
                                        }
                                    >
                                        <span>Upfront payment of</span>
                                        <p>
                                            <NairaSymbol />
                                            {paymentPlans?.[
                                                paymentPlans?.length - 1
                                            ]?.initial_deposit?.toLocaleString(
                                                'en-US',
                                            )}
                                        </p>
                                    </div>
                                ) : (
                                    <div
                                        className={
                                            'ViewSystemSubscriptionPlanCardRowTwo'
                                        }
                                    >
                                        <span>Subscription type</span>
                                        <p>Pay-as-you-go Rental</p>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <div className={'ViewSystemTabsPaymentBottom'}>
                    <div className="ViewSystemContactCTA PaymentSection">
                        <ChatCTA
                            text="Have questions about the payment plans?"
                            textOneFontSize={'14px'}
                        />
                    </div>
                    {paymentModel === 'Lease to Own' &&
                        paymentPlans?.length > 2 && (
                            <div className="ViewSystemPaymentBottomBtnIconWrapper">
                                <ConsumerButton
                                    btnTextColor={'#004AAD'}
                                    btnBgColor={'#FFFFFF'}
                                    borderOutlineColor={'#004AAD'}
                                    hover={'#004AAD'}
                                    btnWidth={'130px'}
                                    btnHeight={'31px'}
                                    role={'see_more'}
                                    btnFontSize="12px"
                                    handleClick={() => setShowModal(true)}
                                >
                                    <div
                                        className={'ViewSystemPaymentBottomBtn'}
                                    >
                                        <img
                                            className="ViewSystemPaymentBottomBtnIconBlue"
                                            src={morePlans}
                                            alt={'more icon'}
                                            role={'presentation'}
                                        />
                                        <img
                                            className="ViewSystemPaymentBottomBtnIconWhite"
                                            src={morePlansWhite}
                                            alt={'more icon'}
                                            role={'presentation'}
                                        />
                                        <span>See More Plans</span>
                                    </div>
                                </ConsumerButton>
                            </div>
                        )}
                </div>
            </div>
        </>
    )
}

RepaymentOptions.propTypes = {
    paymentPlans: PropTypes.array,
    totalCost: PropTypes.number,
    paymentModel: PropTypes.string,
    monthPayment: PropTypes.number,
    preferred_payment_tenure: PropTypes.number,
    setShowExploreModal: PropTypes.func,
    supportedPaymentPlans: PropTypes.array,
}

export default RepaymentOptions
