import ConsumerNavigation from 'src/components/Navigation/consumerNav'
import ConsumerMainSection from 'src/components/ConsumerLandingPageBody'
import ConsumerFooter from 'src/components/ConsumerFooter'
import CSSEPromoCarousel from './PromoCarousel'
import CSSECTA from 'src/components/CSSECTA'
import SeoComponent from 'src/components/Seo'
import SolarSavingsCalculator from './SolarSavingsCalculator'
import FrequentlyAskedQuestions from 'src/components/Faq'
import Testimonial from './Testimonial/Testimonial'
import Benefit from './Benefit/Benefit'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import MaintenancePage from 'src/components/MaintenancePage'
import HowSunfiWorks from './HowSunfiWorks/HowSunfiWorks'
import { useEffect } from 'react'
import { removeToken } from 'src/utils/auth'
import { useLocation } from 'react-router-dom'
import { scrollToById } from 'src/utils/scrollToById'
import CookieNotification from 'src/components/CookieNotificiation'
import { appTracking } from 'src/utils/appTracker'
import { eventTrackers } from 'src/utils/eventTrackers'
import { useGetEnergyProfiles } from 'src/api/shoppingExperience/energy-profile/queries'

// app tracker variables
const pageTitle = 'Home | SunFi | Simplifying and Scaling Clean Energy'
const pageUrlName = window.location.pathname
const pageTracker = 'View consumer page'

const ConsumerHome = () => {
    const location = useLocation()

    const { getEnergyProfilesData, getEnergyProfilesFetching } =
        useGetEnergyProfiles({
            enabled:
                process.env
                    .REACT_APP_FLAG_ENABLE_PUBLIC_PROMOTIONAL_CONSUMER_PROFILE ===
                'true'
                    ? true
                    : false,
            params: {
                is_promotional: true,
                promotional_type: 'public',
            },
        })

    const showPromoCarousel =
        getEnergyProfilesData?.data?.count > 0 &&
        !getEnergyProfilesFetching &&
        process.env
            .REACT_APP_FLAG_ENABLE_PUBLIC_PROMOTIONAL_CONSUMER_PROFILE ===
            'true'
    const showMaintenanceModal =
        process.env.REACT_APP_REDIRECT_TO_MAINTENANCE_PAGE === 'true'
    const showUseTypeForm =
        process.env.REACT_APP_FLAG_SHOW_UPDATED_SSE_FLOW === 'true'

    useEffect(() => {
        removeToken()
    }, [])

    useEffect(() => {
        const scrollToSection = location.state?.scrollToSection

        if (scrollToSection) {
            const targetElement = document.getElementById(scrollToSection)
            if (targetElement) {
                scrollToById(targetElement.id, 150)
            }
        }

        return () => {
            if (location.state?.scrollToSection) {
                location.state.scrollToSection = null
            }
        }
    }, [location.state?.scrollToSection, location])

    useEffect(() => {
        appTracking(
            pageUrlName,
            pageTracker,
            pageTitle,
            eventTrackers['sseLandingPage'].action,
            eventTrackers['sseLandingPage'].label,
            eventTrackers['sseLandingPage'].category,
        )
    }, [])

    return (
        <>
            <SeoComponent
                title="Home | SunFi | Simplifying and Scaling Clean Energy"
                tracker="HomeTracker"
                keywords="SunFi, buy solar, affordable solar, installment payment, Solar installment"
            />
            {showMaintenanceModal ? (
                <SecondaryModal
                    showModal={showMaintenanceModal}
                    closable={false}
                    modalHeight="616px"
                    modalWidth="507px"
                    modalClass="adminModal"
                    content={<MaintenancePage />}
                />
            ) : (
                <>
                    <ConsumerNavigation page={showUseTypeForm ? 'home' : ''} />
                    <ConsumerMainSection />
                    {showPromoCarousel ? (
                        <CSSEPromoCarousel
                            data={getEnergyProfilesData?.data?.data}
                        />
                    ) : null}
                    <Benefit />
                    <HowSunfiWorks />
                    <SolarSavingsCalculator />
                    <FrequentlyAskedQuestions page="sse-home" />
                    <Testimonial userType="consumer" />
                    <CSSECTA />
                    <CookieNotification />
                    <ConsumerFooter />
                </>
            )}
        </>
    )
}

export default ConsumerHome
