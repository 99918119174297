import { useState, useEffect } from 'react'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import {
    initiateSSESignup,
    validateSSESignupOTP,
    resendSSESignupOTP,
    proceedKYCLater,
} from 'src/api/shoppingExperience/get-system'
import UseType from '../components/UseType'
import Verify from '../components/Verify'
import RequiredInfo from '../components/RequiredInfo'
import AddEmail from '../components/AddEmail'
import EmailSent from '../components/EmailSent'
import DealApproved from '../components/DealApproved'
import SystemSelected from '../components/SystemSelected'
import {
    useTypeSchema,
    otpSchema,
    continueLaterEmailSchema,
    newUseTypeSchema,
} from 'src/utils/validationSchema'
import { decrypt, encrypt } from 'src/utils/cryptography'
import { errorHandler } from 'src/utils/errorHandler'
import { formatPhoneNumber } from 'src/utils/formatting'
import { setToken, encodeUserInfo } from 'src/utils/auth'
import useOTPVerify from 'src/utils/Hooks/useOTPVerify'
import { handleAppTrackingOnSignup } from '../utils'
import { appTracking } from 'src/utils/appTracker'
import ChangePaymentModel from '../components/ChangePaymentModel'

const useGettingStarted = (
    useType,
    selectedSolutionId,
    isPromo,
    handlePromoDropoff,
    initiatePayload,
    showUseTypeField,
    setShowModal,
    context,
    handleSwitchUseType,
    useV2SaveUseTypeFn,
    activePaymentModel,
    handleChangePaymentModel,
    selectedDifferentPaymentPlan,
    undoPaymentOptionSelection,
    isClosedCluster,
    isPromoCluster,
) => {
    const [savedContactInfo, setSavedContactInfo] = useState({})
    const [step, setStep] = useState(1)
    const [inputs, setInputs] = useState({
        useType: useType,
        email: '',
        phone: '',
    })
    const [errors, setErrors] = useState({})
    const [otp, setOtp] = useState(['', '', '', '', '', ''])
    const [otpError, setOtpError] = useState(false)
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('')
    const [showToastError, setShowToastError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [resent, setResent] = useState(false)
    const history = useHistory()
    const clusterCode = history?.location?.state?.clusterCode ?? ''
    const payment_model = history?.location.state?.payment_model
    const pageTitle =
        'KYC - Getting Started | SunFi | Simplifying and Scaling Clean Energy'
    const pageUrlName = window.location.pathname
    const pageTracker = 'KYC_SSE'
    const onboardingType = history?.location?.state?.onboardingType

    const btnTextMapping = {
        1: isClosedCluster
            ? 'Get started with KYC'
            : selectedDifferentPaymentPlan
            ? 'Yes, Continue'
            : 'Confirm',
        2: `Verify ${
            savedContactInfo?.contactType === 'email' ? 'Email' : 'Phone'
        }`,
        3: payment_model === 'Outright Sale' ? 'Checkout' : 'Yes, Continue',
        4: 'Confirm',
        5: 'Great, Thank you',
    }

    // initiate signup to get otp
    const customerTypeMapping = {
        'Business use': 'BUSINESS',
        'Residential use': 'RESIDENTIAL',
    }

    const customerTypeRemapping = {
        BUSINESS: 'Business use',
        RESIDENTIAL: 'Residential use',
    }
    const signupPayload = {
        customer_type: customerTypeMapping[inputs?.useType],
        selected_solution_id: selectedSolutionId,
        ...(clusterCode !== '' && { cluster_code: clusterCode }),
        ...(inputs?.email !== '' &&
            savedContactInfo.contactType === 'email' && {
                email: inputs?.email,
            }),
        ...(inputs?.phone !== '' &&
            savedContactInfo.contactType === 'phone' && {
                phone_number: formatPhoneNumber(inputs?.phone),
            }),
        ...(inputs?.phone !== '' &&
            isPromo && {
                phone_number: formatPhoneNumber(inputs?.phone),
            }),
    }

    const initiateSSESignupMutation = useMutation({
        mutationKey: ['initiateSSESignup'],
        mutationFn: payload => initiateSSESignup(payload),
        onSuccess: () => {
            setStep(2)
            appTracking(
                pageUrlName,
                pageTracker,
                pageTitle,
                'SSE_SELECT_USE_TYPE',
                'SMART_SHOPPING_EXPERIENCE_KYC',
                'SSE_GETTING_STARTED',
                ['MP', 'GA'],
                'event',
                {
                    signupPayload,
                },
            )
        },
        onError: error => {
            setShowToastError(true)
            setErrorMessage(errorHandler(error?.response?.data))
        },
    })
    const { isLoading: initialSSESignupLoading } = initiateSSESignupMutation

    // validate signup otp
    const validateOTPPayload = {
        code: otp.join(''),
        ...(inputs?.email !== '' &&
            savedContactInfo.contactType === 'email' && {
                email: inputs?.email,
            }),
        ...(inputs?.phone !== '' &&
            savedContactInfo.contactType === 'phone' && {
                phone_number: formatPhoneNumber(inputs?.phone),
            }),
        ...(inputs?.phone !== '' &&
            isPromo && {
                phone_number: formatPhoneNumber(inputs?.phone),
            }),
    }
    const validateSSESignupOTPMutation = useMutation({
        mutationKey: ['validateSSESignupOTP'],
        mutationFn: payload => validateSSESignupOTP(payload),
        onSuccess: data => {
            setToken(data?.data?.data?.token?.access)
            encodeUserInfo(data)
            if (isClosedCluster || isPromoCluster) {
                history.push({
                    pathname: '/consumer/account-setup/overview',
                    state: {
                        payment_model,
                        onboardingType,
                    },
                })
            } else {
                setStep(3)
            }

            handleAppTrackingOnSignup(
                savedContactInfo?.contactType === 'email'
                    ? 'validatedEmail'
                    : 'validatedPhone',
            )
        },
        onError: error => {
            setShowToastError(true)
            setErrorMessage(errorHandler(error?.response.data))
        },
    })
    const { isLoading: validateSSESignupOTPLoading } =
        validateSSESignupOTPMutation

    // resend SSE Signup otp
    const resendOTPPayload = {
        ...(inputs?.email !== '' &&
            savedContactInfo.contactType === 'email' && {
                email: inputs?.email,
            }),
        ...(inputs?.phone !== '' &&
            savedContactInfo.contactType === 'phone' && {
                phone_number: formatPhoneNumber(inputs?.phone),
            }),
    }
    const resendSSESignupOTPMutation = useMutation({
        mutationKey: ['resendSSESignupOTP'],
        mutationFn: payload => resendSSESignupOTP(payload),
        onSuccess: () => {
            setResent(true)
            const timeout = setTimeout(() => setResent(false), [2000])
            return () => clearTimeout(timeout)
        },
        onError: error => {
            setShowToastError(true)
            setResent(false)
            setErrorMessage(errorHandler(error?.response.data))
        },
    })

    // proceed kyc later
    const proceedKYCLaterMutation = useMutation({
        mutationKey: ['proceedKYCLater'],
        mutationFn: payload => proceedKYCLater(payload),
        onSuccess: () => {
            setStep(5)
            appTracking(
                pageUrlName,
                pageTracker,
                pageTitle,
                'SSE_PROCEED_TO_KYC_LATER',
                'SMART_SHOPPING_EXPERIENCE_KYC',
                'SSE_SAVE_TO_CONTINUE_LATER',
            )
        },
        onError: error => {
            setShowToastError(true)
            setErrorMessage(errorHandler(error?.response.data))
        },
    })

    const handleResendSignupOtp = () => {
        resendSSESignupOTPMutation.mutate(resendOTPPayload)
    }

    const handleSaveUseType = () => {
        useTypeSchema
            .validate(inputs, {
                context: { contact: savedContactInfo?.contactType },
                abortEarly: false,
            })
            .then(() => {
                setErrors('')
                const newData = {
                    ...savedContactInfo,
                    useType: inputs.useType ?? '',
                }
                if (!isPromo) {
                    localStorage.setItem(
                        'sunfiUserSSEGettingStartedInfo',
                        encrypt(JSON.stringify(newData)),
                    )
                }
                isPromo && handlePromoDropoff(inputs?.phone)
                initiateSSESignupMutation.mutate(signupPayload)
                handleAppTrackingOnSignup(
                    savedContactInfo?.contactType === 'email'
                        ? 'confirmedEmail'
                        : 'confirmedPhone',
                )
            })
            .catch(err => {
                let errList = {}
                err?.inner?.forEach(e => {
                    errList = { ...errList, [e.path]: e.message }
                })
                setErrors(errList)
            })
    }

    const handleSaveUseTypeV2 = () => {
        const fields = {
            ...(inputs?.email !== '' &&
                savedContactInfo.contactType === 'email' && {
                    email: inputs?.email,
                }),
            ...(inputs?.phone !== '' &&
                savedContactInfo.contactType === 'phone' && {
                    phone_number: formatPhoneNumber(inputs?.phone),
                }),
            ...initiatePayload,
            ...(showUseTypeField || isClosedCluster
                ? {
                      customer_type: customerTypeMapping[inputs.useType],
                  }
                : {}),
        }

        const isPromofields = {
            ...(inputs?.email !== '' && {
                email: inputs?.email,
            }),
            ...(inputs?.phone !== '' && {
                phone_number: formatPhoneNumber(inputs?.phone),
            }),
            ...initiatePayload,
        }

        newUseTypeSchema
            .validate(isPromoCluster ? isPromofields : fields, {
                context: { contact: savedContactInfo?.contactType },
                abortEarly: false,
            })
            .then(() => {
                setErrors('')
                const newData = {
                    ...savedContactInfo,
                    useType: fields.customer_type,
                }
                if (!isPromo) {
                    localStorage.setItem(
                        'sunfiUserSSEGettingStartedInfo',
                        encrypt(JSON.stringify(newData)),
                    )
                }
                isPromo && handlePromoDropoff(inputs?.phone)
                initiateSSESignupMutation.mutate(
                    isPromoCluster ? isPromofields : fields,
                )
                handleAppTrackingOnSignup(
                    savedContactInfo?.contactType === 'email'
                        ? 'confirmedEmail'
                        : 'confirmedPhone',
                )
            })
            .catch(err => {
                let errList = {}
                err?.inner?.forEach(e => {
                    errList = { ...errList, [e.path]: e.message }
                })
                setErrors(errList)
            })
    }
    // submit otp
    const handleOtpSubmit = () => {
        otpSchema
            .validate(otp, { abortEarly: false })
            .then(() => {
                setOtpError(false)
                validateSSESignupOTPMutation.mutate(validateOTPPayload)
                appTracking(
                    pageUrlName,
                    pageTracker,
                    pageTitle,
                    'SSE_VALIDATE_OTP',
                    'SMART_SHOPPING_EXPERIENCE_KYC',
                    'SSE_GETTING_STARTED',
                )
            })
            .catch(() => {
                setOtpError(true)
            })
    }

    // submit email to continue later
    const handleContinueLaterEmailSubmit = () => {
        continueLaterEmailSchema
            .validate({ email: email }, { abortEarly: false })
            .then(() => {
                setEmailError('')
                proceedKYCLaterMutation.mutate({ email: email })
            })
            .catch(err => {
                if (err && err.inner && err.inner.length > 0) {
                    setEmailError(err.inner[0].message)
                }
            })
    }

    const closeModal = () => {
        setShowModal(false)
        setStep(1)
        setInputs({
            useType: customerTypeRemapping[savedContactInfo?.useType],
            email: savedContactInfo?.email,
            ...(savedContactInfo?.phone && {
                phone: savedContactInfo?.phone?.replace('+234', 0),
            }),
        })
        setErrors({})
        setOtp(['', '', '', '', '', ''])
        setOtpError(false)
        setEmail('')
        setEmailError('')
    }

    useOTPVerify({ otp, handleOtpSubmit })

    const stepMapping = {
        1: selectedDifferentPaymentPlan ? (
            <ChangePaymentModel
                activePaymentModel={activePaymentModel}
                useType={inputs.useType}
            />
        ) : isClosedCluster ? (
            <SystemSelected
                showToastError={showToastError}
                errorMessage={errorMessage}
            />
        ) : (
            <UseType
                contact={savedContactInfo?.contactType}
                inputs={inputs}
                setInputs={setInputs}
                errors={errors}
                setErrors={setErrors}
                showToastError={showToastError}
                errorMessage={errorMessage}
                showUseTypeField={showUseTypeField}
                isPromoCluster={isPromoCluster}
            />
        ),
        2: (
            <Verify
                contact={savedContactInfo?.contactType}
                otp={otp}
                setOtp={setOtp}
                otpError={otpError}
                inputs={inputs}
                showToastError={showToastError}
                errorMessage={errorMessage}
                handleResendOtp={handleResendSignupOtp}
                resent={resent}
                setResent={setResent}
                disabled={validateSSESignupOTPLoading}
            />
        ),
        3:
            payment_model === 'Outright Sale' ? (
                <DealApproved />
            ) : (
                <RequiredInfo inputs={inputs} />
            ),
        4: (
            <AddEmail
                email={email}
                setEmail={setEmail}
                emailError={emailError}
            />
        ),
        5: <EmailSent />,
    }
    const handlePrimaryBtnClick = () => {
        if (step === 1) {
            selectedDifferentPaymentPlan
                ? handleChangePaymentModel()
                : useV2SaveUseTypeFn
                ? handleSaveUseTypeV2()
                : handleSaveUseType()
        }
        if (step === 2) {
            handleOtpSubmit()
        }
        if (step === 3 && payment_model === 'Outright Sale') {
            history.push('/consumer/account-setup/outright-sale-information')
        }
        if (step === 3 && payment_model !== 'Outright Sale') {
            if (context === 'switch_use_type') {
                handleSwitchUseType()
                closeModal()
            } else
                history.push({
                    pathname: '/consumer/account-setup/overview',
                    state: {
                        payment_model,
                        onboardingType,
                    },
                })
            appTracking(
                pageUrlName,
                pageTracker,
                pageTitle,
                'SSE_PROCEED_TO_KYC',
                'SMART_SHOPPING_EXPERIENCE_KYC',
                'SSE_GETTING_STARTED',
            )
        }

        if (step === 4) {
            handleContinueLaterEmailSubmit()
        }

        if (step === 5) {
            window.location.href = '/'
        }
    }

    const handleSecondaryBtnClick = () => {
        if (isClosedCluster) {
            closeModal()
        }
        if (selectedDifferentPaymentPlan) {
            undoPaymentOptionSelection()
        }
        if (step === 3 && payment_model !== 'Outright Sale') {
            if (savedContactInfo?.contactType === 'email') {
                proceedKYCLaterMutation.mutate()
            } else {
                setStep(4)
            }
        }
    }

    useEffect(() => {
        try {
            const data = localStorage.getItem('sunfiUserSSEGettingStartedInfo')

            if (data !== null) {
                const decryptedData = JSON.parse(decrypt(data))
                if (decryptedData) {
                    setSavedContactInfo(decryptedData)
                    setInputs(prev => ({
                        ...prev,
                        email: decryptedData?.email,
                        ...(decryptedData?.phone && {
                            phone: decryptedData?.phone?.replace('+234', 0),
                        }),
                        ...(decryptedData?.useType
                            ? {
                                  useType:
                                      customerTypeRemapping[
                                          decryptedData?.useType
                                      ],
                              }
                            : {}),
                    }))
                    return
                }
            }
        } catch (error) {
            setInputs(prev => ({
                ...prev,
                email: '',
                phone: '',
            }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        closeModal,
        step,
        payment_model,
        stepMapping,
        btnTextMapping,
        initialSSESignupLoading,
        validateSSESignupOTPLoading,
        handlePrimaryBtnClick,
        handleSecondaryBtnClick,
    }
}

export default useGettingStarted
