import { useEffect, useState } from 'react'
import styles from './solarsystemslistingsv2.module.scss'
import ConsumerNavigation from 'src/components/Navigation/consumerNav'
import ConsumerFooter from 'src/components/ConsumerFooter'
import CookieNotification from 'src/components/CookieNotificiation'
import Header from './components/Header'
import ProfileCard from './components/ProfileCard'
import Systems from './components/Systems'
import SharedForm from './components/SharedForm'
import { SolarSystemsContext, useSolarSystems } from './hooks/useSolarSystems'
import { Skeleton } from 'antd'
import SeoComponent from 'src/components/Seo'
import FrequentlyAskedQuestions from 'src/components/Faq'

const SolarSystemsListingsV2 = () => {
    const {
        ParentWrapper,
        ParentWrapper_Shared,
        ChildWrapper,
        MainWrapper,
        NoMargin,
    } = styles
    const isAllSystems = location.pathname.includes('/all-systems')
    const isPromo = location.pathname.includes('/promo')

    const {
        getEnergyProfileSolutionsData,
        getEnergyProfileSolutionsFetching,
        currentPage,
        setCurrentPage,
        sortBy,
        setSortBy,
        getEnergyProfileSolutionsFilterData,
        getEnergyProfileSolutionsFilterFetching,
        filterInputs,
        setFilterInputs,
        clearFilters,
        showBuildCustomModal,
        setShowBuildCustomModal,
        getEnergyProfileData,
        getEnergyProfileFetching,
        isClosedCluster,
        setIsClosedCluster,
        showClosedClusterModal,
    } = useSolarSystems()

    const [sharedProfile, setSharedProfile] = useState(false)

    useEffect(() => {
        const isClosedCluster =
            getEnergyProfileData?.data?.data?.promotional_type === 'PRIVATE'
        setIsClosedCluster(isClosedCluster)

        if (getEnergyProfileData) {
            setSharedProfile(location.pathname.includes('/shared'))
        }
        // eslint-disable-next-line
    }, [getEnergyProfileData])

    return (
        <>
            <SeoComponent
                title="Solar systems | SunFi | Simplifying and Scaling Clean Energy"
                tracker="SOLAR_SYSTEM_LISTING"
                keywords="SunFi, Inverters, Solar Panels, 10VA solar system, 10kw solar battery"
            />

            <SolarSystemsContext.Provider
                value={{
                    getEnergyProfileSolutionsData,
                    getEnergyProfileSolutionsFetching,
                    currentPage,
                    setCurrentPage,
                    sortBy,
                    setSortBy,
                    getEnergyProfileSolutionsFilterData,
                    getEnergyProfileSolutionsFilterFetching,
                    filterInputs,
                    setFilterInputs,
                    clearFilters,
                    showBuildCustomModal,
                    setShowBuildCustomModal,
                    getEnergyProfileData,
                    getEnergyProfileFetching,
                    isClosedCluster,
                    setIsClosedCluster,
                    showClosedClusterModal,
                    isPromo,
                }}
            >
                <ConsumerNavigation
                    showUserBtn
                    page={isClosedCluster ? 'closedCluster' : 'getStarted'}
                />
                <div
                    className={`${ParentWrapper} ${
                        sharedProfile && ParentWrapper_Shared
                    }`}
                >
                    {sharedProfile && <SharedForm />}
                    <div className={ChildWrapper}>
                        <div
                            className={`${MainWrapper} ${
                                isAllSystems && NoMargin
                            }`}
                        >
                            {!isClosedCluster && <Header />}
                            {!isAllSystems && !getEnergyProfileFetching ? (
                                <ProfileCard />
                            ) : !isAllSystems && getEnergyProfileFetching ? (
                                <Skeleton
                                    active
                                    loading
                                    paragraph={{ rows: 6, width: '100%' }}
                                />
                            ) : null}
                            <Systems />
                            {isClosedCluster && (
                                <FrequentlyAskedQuestions
                                    useV2
                                    page="closedCluster"
                                />
                            )}
                        </div>
                    </div>

                    <CookieNotification />
                    <ConsumerFooter />
                </div>
            </SolarSystemsContext.Provider>
        </>
    )
}

export default SolarSystemsListingsV2
