import residenceIcon from 'src/assets/images/residence-icon.svg'
import businessIcon from 'src/assets/images/business-icon.svg'
import { offerStatusMap } from 'src/utils/Data/status'

export const requiredResidentialInfo = [
    'Up-to-date, verified 6-12 months Bank statement',
    'Your Name',
    'Monthly Income',
    'BVN/NIN',
]

export const requiredBusinessInfo = [
    'Up-to-date, verified 12-24 months Bank statement',
    'Business Name',
    'Business Location',
    'RC number',
    'Monthly Business Revenue',
]

export const useTypeDetails = [
    {
        icon: residenceIcon,
        title: 'Get for home or unregistered business',
        description:
            'Getting the system for home or unregistered business e.g self-employed etc.',
        value: 'individual',
    },
    {
        icon: businessIcon,
        title: 'Get for Registered Business',
        description:
            'Getting the system for registered business e.g LLC, NGOs, etc.',
        value: 'business',
    },
]

export const approvalStatus = [
    {
        id: 'approved',
        payment: 'Approved',
    },
    {
        id: 'awaiting_approval',
        payment: 'Awaiting Approval',
    },
    {
        id: 'in_progress',
        payment: 'In Progress',
    },
    {
        id: 'declined',
        payment: 'Declined',
    },
    {
        id: 'declined_and_re_estimate',
        payment: 'Declined (Re-Estimate)',
    },
]

export const applicationStatus = () => {
    return Object.entries(offerStatusMap).map(([status, title]) => ({
        id: status,
        payment: title,
    }))
}
