import PropsTypes from 'prop-types'
import { Steps } from 'antd'
import '../viewsystem.scss'
import { stepsItems, getStartedStepsItems } from '../data'

const PackageSteps = ({ page, style = {} }) => {
    const isPromoPage = page === 'promo'
    return (
        <div
            className={'ViewSystemStepsWrapper'}
            style={{ border: isPromoPage && 0, ...style }}
        >
            <h4 style={{ marginBottom: isPromoPage && '-6px' }}>
                How getting a package works on SunFi
            </h4>
            <Steps
                direction="vertical"
                className={`ViewSystemSteps ${
                    isPromoPage && 'ViewGetsystemSteps'
                }`}
                items={isPromoPage ? getStartedStepsItems : stepsItems}
            />
        </div>
    )
}

PackageSteps.propTypes = {
    page: PropsTypes.string,
    style: PropsTypes.object,
}

export default PackageSteps
