import PropTypes from 'prop-types'
import { urgencyOptions, useTypeDetails } from './data'
import { ReactComponent as Checked } from 'src/assets/images/circle-check-no-stroke-white-fill.svg'
import ButtonComponent from 'src/components/Button'
import s from './selectusetype.module.scss'
import { Link } from 'react-router-dom'
import { InlineLoader } from 'src/components/Loader'
import { SelectField } from 'src/components/InputFields'
import { landingPageEvents } from 'src/utils/mp-event-tracker/landing-page'
import { appTracking } from 'src/utils/appTracker'

const SelectUseType = ({
    handleGetStarted,
    handleViewAllSystems,
    useType,
    setUseType,
    errors,
    setErrors,
    getStartedBtnLoading,
    viewAllSolutionsBtnLoading,
    useTypeFormInputs,
    setUseTypeFormInputs,
    showNewForm,
    handleCheckReturningCustomerStatus,
    allowReturningCustomers,
    getReturningCustomerStatusLoading,
}) => {
    const handleSelectChange = (name, value) => {
        setUseTypeFormInputs(prev => ({
            ...prev,
            [name]: value,
        }))
    }

    // app tracker variables
    const pageTitle = 'Home | SunFi | Simplifying and Scaling Clean Energy'
    const pageUrlName = window.location.pathname
    const pageTracker = 'View consumer page'

    const showAllAvailableSolutions =
        process.env.REACT_APP_FLAG_SHOW_AVAILABLE_SOLUTIONS === 'true'

    const clearError = errName => {
        setErrors(prevErrors => {
            const newErrors = { ...prevErrors }
            delete newErrors[errName]
            return newErrors
        })
    }

    return (
        <div>
            <SelectField
                name={'urgency'}
                initialOption="When do you need the solar installation?"
                selectWidth={'100%'}
                marginBottom={'20px'}
                values={urgencyOptions?.map(option => ({
                    value: option,
                }))}
                value={useTypeFormInputs.urgency}
                currentSelected={useTypeFormInputs.urgency}
                handleChange={(name, value) => {
                    handleSelectChange(name, value)
                    clearError('urgencyErr')
                    clearError('urgency')
                }}
                withCheckBox
                floatingLabel={
                    useTypeFormInputs?.urgency !== '' &&
                    'When do you need the solar installation?'
                }
                errorMessage={
                    showNewForm ? errors?.urgency : errors?.urgencyErr
                }
                dropdownPositionRelative
            />
            <div className={showNewForm ? s.newform : s.form}>
                {!showNewForm && (
                    <>
                        <h2>What best describes your need?</h2>
                        <div className={s.options}>
                            {useTypeDetails?.map((each, i) => (
                                <div
                                    key={i}
                                    className={s.option}
                                    role={'button'}
                                    onClick={() => {
                                        setUseType(each.value)
                                        setErrors({ ...errors, useTypeErr: '' })
                                        appTracking(
                                            pageUrlName,
                                            pageTracker,
                                            pageTitle,
                                            landingPageEvents[
                                                i === 0
                                                    ? 'residential'
                                                    : 'business'
                                            ].action,
                                            landingPageEvents[
                                                i === 0
                                                    ? 'residential'
                                                    : 'business'
                                            ].label,
                                            landingPageEvents[
                                                i === 0
                                                    ? 'residential'
                                                    : 'business'
                                            ].category,
                                            ['MP', 'GA'],
                                            'event',
                                        )
                                    }}
                                    style={{
                                        backgroundColor:
                                            useType === each.value
                                                ? '#E2EEFF'
                                                : '#F9FCFF',
                                        border:
                                            useType === each.value
                                                ? '0.5px solid #E2EEFF'
                                                : '0.5px solid #E2EEFF',
                                    }}
                                >
                                    <img
                                        src={each.icon}
                                        alt={each.title}
                                        className={s.optionImg}
                                    />
                                    <div>
                                        <h4>{each.title}</h4>
                                        <p>{each.description}</p>
                                    </div>
                                    <Checked
                                        className={s.checkIcon}
                                        style={{
                                            stroke:
                                                useType === each.value
                                                    ? '#004AAD'
                                                    : '#E2EEFF',
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    </>
                )}
                {(errors?.useType || errors?.useTypeErr) && (
                    <p className={s.error}>
                        {showNewForm ? errors?.useType : errors?.useTypeErr}
                    </p>
                )}
                <ButtonComponent
                    btnBgColor={'#004AAD'}
                    btnTextColor={'#FFFFFF'}
                    btnWidth={'100%'}
                    btnHeight={'56px'}
                    marginTop={showNewForm ? '10px' : '24px'}
                    handleClick={() => {
                        if (allowReturningCustomers) {
                            handleCheckReturningCustomerStatus()
                        } else {
                            handleGetStarted()
                        }
                        appTracking(
                            pageUrlName,
                            pageTracker,
                            pageTitle,
                            landingPageEvents['getStarted'].action,
                            landingPageEvents['getStarted'].label,
                            landingPageEvents['getStarted'].category,
                            ['MP', 'GA'],
                            'event',
                        )
                    }}
                >
                    {(!showNewForm && getStartedBtnLoading) ||
                    getReturningCustomerStatusLoading ? (
                        <InlineLoader />
                    ) : (
                        'Get started'
                    )}
                </ButtonComponent>
            </div>
            {showAllAvailableSolutions && !showNewForm && (
                <>
                    <div className={s.divider}>
                        <hr />
                        <p>OR</p>
                        <hr />
                    </div>
                    <ButtonComponent
                        btnBgColor={'#FFFFFF'}
                        btnTextColor={'#004AAD'}
                        btnWidth={'100%'}
                        btnHeight={'37px'}
                        marginTop={'24px'}
                        btnTextColorOutline={'#E2EEFF'}
                        handleClick={() => {
                            if (allowReturningCustomers) {
                                handleCheckReturningCustomerStatus()
                            } else {
                                handleViewAllSystems()
                            }
                            appTracking(
                                pageUrlName,
                                pageTracker,
                                pageTitle,
                                landingPageEvents['allSystems'].action,
                                landingPageEvents['allSystems'].label,
                                landingPageEvents['allSystems'].category,
                                ['MP', 'GA'],
                                'event',
                            )
                        }}
                    >
                        {viewAllSolutionsBtnLoading ? (
                            <InlineLoader />
                        ) : (
                            'View all available solutions'
                        )}
                    </ButtonComponent>
                </>
            )}

            <p className={s.disclaimer}>
                By clicking &quot;Get started&quot;
                {showNewForm
                    ? ', you agree'
                    : ' or "View all available solutions"'}
                <br />
                {showNewForm ? '' : 'you agree '}to SunFi’s{' '}
                <Link to="/terms-of-service" target="_blank">
                    Terms of use
                </Link>{' '}
                &{' '}
                <Link to="/privacy-policy" target="_blank">
                    Privacy policy
                </Link>
            </p>
        </div>
    )
}

SelectUseType.propTypes = {
    handleGetStarted: PropTypes.func,
    handleViewAllSystems: PropTypes.func,
    useType: PropTypes.string,
    setUseType: PropTypes.func,
    errors: PropTypes.object,
    setErrors: PropTypes.func,
    getStartedBtnLoading: PropTypes.bool,
    viewAllSolutionsBtnLoading: PropTypes.bool,
    useTypeFormInputs: PropTypes.object,
    setUseTypeFormInputs: PropTypes.func,
    showNewForm: PropTypes.bool,
    handleCheckReturningCustomerStatus: PropTypes.func,
    allowReturningCustomers: PropTypes.bool,
    getReturningCustomerStatusLoading: PropTypes.bool,
}

export default SelectUseType
