import { useState } from 'react'
import PropTypes from 'prop-types'
import { Dropdown, Menu } from 'antd'
import { ReactComponent as WarningIcon } from 'src/assets/images/warning-icon.svg'
import { ReactComponent as Save } from 'src/assets/images/save.svg'
import '../accountsetupoverview.scss'
import { ConsumerButton } from 'src/components/Button'
import { isMobile } from 'src/utils/mediaQueries'
import { decodeUserInfo } from 'src/utils/auth'
import { ReactComponent as DropdownIcon } from 'src/assets/images/dropdown.svg'
import LogOutIcon from 'src/assets/images/logout-icon.svg'
import useLogOut from 'src/utils/Hooks/useLogOut'
import { useAppContext } from 'src/appContext/AppContext'
import { useHistory } from 'react-router-dom'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import styles from 'src/components/ActionModalV2/actionmodalv2.module.scss'
import { SelectField } from 'src/components/InputFields'
import ChatCTA from 'src/components/ChatCTA'
import { ReactComponent as CircleWhiteIcon } from 'src/assets/images/circle-right-white.svg'
import useRunCreditDecision from '../hooks/useRunCreditDecision'
import Toast from 'src/components/Toast'

const { ActionHeader, ActionContainer, ActionText } = styles
const data = [
    'Credit decision is Yes',
    'Credit decision is Yes With Condition',
    'Credit decision is Maybe',
    'Credit decision is No',
    'Credit decision is Undecided due to errors',
]

const AccountSetupNav = ({
    handleSaveProgress,
    handleSubmit,
    completed,
    hasPaidOutrightly,
    showSimulateCreditDecision,
    setShowSimulateCreditDecision,
    estimationId,
    openActionModal,
}) => {
    const userInfo = decodeUserInfo()
    const history = useHistory()
    const isAdminUser = userInfo?.isAdminWorkspaceUser === 'true'
    const initials =
        userInfo?.firstName?.charAt(0) + userInfo?.lastName?.charAt(0)
    const { logOut, logoutLoading } = useLogOut()
    const { isCustomerApproved, setSimulatedCreditDecision } = useAppContext()
    const handleGoBackToProfile = () => {
        history.push('/consumer/workspace')
    }
    const [type, setType] = useState('')

    const onRunCreditDecisionSuccess = () => {
        setShowSimulateCreditDecision(false)
        setType('')
        openActionModal('admin-submitted-kyc-successfully')
    }

    const dropdownMenu = (
        <Menu className="ASONavWrapper_Admin_NavBar_Menu">
            <Menu.Item onClick={() => logOut()}>
                <span
                    style={{ display: 'flex', alignItems: 'center', gap: 16 }}
                >
                    <img src={LogOutIcon} alt="arrow" />
                    <span>Log Out</span>
                </span>
            </Menu.Item>
        </Menu>
    )

    const payloadFormat = type.includes('With Condition')
        ? 'YES_WITH_CONDITION'
        : type.includes('Maybe')
        ? 'MAYBE'
        : type.includes('No')
        ? 'NO'
        : type.includes('Undecided')
        ? 'UNDECIDED'
        : 'YES'

    const {
        runCreditDecisionMutate,
        runCreditDecisionLoading,
        runCreditDecisionToast,
    } = useRunCreditDecision({
        estimationId,
        onSuccess: onRunCreditDecisionSuccess,
        selectedCreditDecision: payloadFormat,
    })

    const adminCanRunCreditDecision =
        process.env.REACT_APP_FLAG_ADMIN_ASSISTED_KYC === 'true' &&
        isAdminUser &&
        completed &&
        !isCustomerApproved &&
        !hasPaidOutrightly

    return (
        <>
            <SecondaryModal
                showModal={showSimulateCreditDecision}
                onCancel={() => setShowSimulateCreditDecision(false)}
                modalWidth={'475px'}
                title={isAdminUser ? 'Submit info' : 'Submit your info'}
                v2
                content={
                    <div style={{ position: 'relative' }}>
                        {runCreditDecisionToast?.showToast && (
                            <Toast
                                position="absolute"
                                top="30px"
                                messageType={
                                    runCreditDecisionToast?.messageType
                                }
                                message={runCreditDecisionToast?.toastMessage}
                            />
                        )}
                        <div className={ActionContainer}>
                            <WarningIcon />
                            <h2 className={ActionHeader}>
                                {isAdminUser
                                    ? "Submit customer's info"
                                    : 'Submit info and select payment plan'}
                            </h2>
                            <p className={ActionText}>
                                {isAdminUser
                                    ? 'The next step is to verify if the customer is eligible to get their solar system financed by SunFi.'
                                    : 'Great work! The next step is to verify if you&#39;re eligible of getting your solar system finance by SunFi'}
                            </p>
                            <div className="AsoNav">
                                <SelectField
                                    initialOption="Select Credit Decision Result"
                                    floatingLabel="Select Credit Decision Result"
                                    floatLabelOnSelectedValue
                                    values={data.map(option => ({
                                        value: option,
                                    }))}
                                    selectWidth={'100%'}
                                    name="type"
                                    handleChange={(name, value) => {
                                        setType(value)
                                    }}
                                    currentSelected={type}
                                    selectedValue={type}
                                    marginTop="24px"
                                    withCheckBox
                                />
                            </div>
                        </div>
                    </div>
                }
                showFooter
                footerLeftContent={isAdminUser ? null : 'chat'}
                primaryBtnText="Confirm"
                handlePrimaryBtnClick={() => {
                    if (isAdminUser) {
                        runCreditDecisionMutate()
                    } else {
                        history.push('/consumer/account-setup/credit-decision')
                        setSimulatedCreditDecision(payloadFormat)
                    }
                }}
                primaryBtnDisabled={type?.length === 0}
                primaryBtnWidth={'160px'}
                primaryBtnHeight={isMobile ? '44.5px' : '53px'}
                primaryBtnLoading={runCreditDecisionLoading}
            />

            {isAdminUser ? (
                <nav className={'ASONavWrapper_Admin'}>
                    <img
                        src={
                            'https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Blue.png'
                        }
                        alt={'SunFi'}
                    />
                    <div className={'ASONavWrapper_Admin_Right'}>
                        {adminCanRunCreditDecision && (
                            <ConsumerButton
                                btnBgColor="#004AAD"
                                btnTextColor="#FFFFFF"
                                btnHeight={isMobile ? '44px' : '50px'}
                                btnWidth={isMobile ? '94px' : '117px'}
                                handleClick={() => {
                                    process.env
                                        .REACT_APP_SHOW_SIMULATED_CREDIT_DECISION_ENGINE ===
                                    'true'
                                        ? setShowSimulateCreditDecision(true)
                                        : openActionModal(
                                              'admin-initiate-submit-kyc',
                                          )
                                }}
                            >
                                Submit
                            </ConsumerButton>
                        )}
                        <div className={'ASONavWrapper_Admin_Avatar'}>
                            <span>{initials?.toUpperCase()}</span>
                        </div>
                        <Dropdown
                            trigger={['click']}
                            overlay={dropdownMenu}
                            placement="bottomRight"
                        >
                            <DropdownIcon style={{ cursor: 'pointer' }} />
                        </Dropdown>
                    </div>
                </nav>
            ) : (
                <nav
                    className={'ASONavWrapper'}
                    data-testid="account-setup-nav"
                >
                    <div className={'ASONavLeft'}>
                        {hasPaidOutrightly ? (
                            <ChatCTA />
                        ) : (
                            <>
                                <h1>Set up your SunFi account</h1>
                                <p>This takes approximately 10mins</p>
                            </>
                        )}
                    </div>
                    {hasPaidOutrightly ? (
                        <ConsumerButton
                            btnWidth={'116px'}
                            btnHeight={'50px'}
                            btnBgColor={'#004AAD'}
                            btnTextColor={'#FFFFFF'}
                            hoverNoFill
                            type={logoutLoading ? undefined : 'iconBtn'}
                            handleClick={() => logOut()}
                            loading={logoutLoading}
                        >
                            Logout
                            <CircleWhiteIcon />
                        </ConsumerButton>
                    ) : (
                        <div className="ASONavButtons">
                            {!isCustomerApproved && (
                                <div
                                    className={'ASONavRight'}
                                    role={'button'}
                                    onClick={handleSaveProgress}
                                >
                                    <div
                                        className={`ASONavButtons_IconBtn ${
                                            completed ? '' : 'SmallIcon'
                                        }`}
                                    >
                                        <Save role={'presentation'} />
                                    </div>
                                    {!completed && (
                                        <p>I want to continue later</p>
                                    )}
                                </div>
                            )}
                            {completed && (
                                <>
                                    <div className="ASONavButtons_Divider"></div>
                                    {isCustomerApproved ? (
                                        <ConsumerButton
                                            btnBgColor="#004AAD"
                                            btnTextColor="#FFFFFF"
                                            btnHeight={
                                                isMobile ? '44px' : '50px'
                                            }
                                            btnWidth={
                                                isMobile ? '94px' : '117px'
                                            }
                                            handleClick={handleGoBackToProfile}
                                        >
                                            Back to profile
                                        </ConsumerButton>
                                    ) : (
                                        <ConsumerButton
                                            btnBgColor="#004AAD"
                                            btnTextColor="#FFFFFF"
                                            btnHeight={
                                                isMobile ? '44px' : '50px'
                                            }
                                            btnWidth={
                                                isMobile ? '94px' : '117px'
                                            }
                                            handleClick={() => {
                                                process.env
                                                    .REACT_APP_SHOW_SIMULATED_CREDIT_DECISION_ENGINE ===
                                                'true'
                                                    ? setShowSimulateCreditDecision(
                                                          true,
                                                      )
                                                    : handleSubmit()
                                            }}
                                        >
                                            Submit
                                        </ConsumerButton>
                                    )}
                                </>
                            )}
                        </div>
                    )}
                </nav>
            )}
        </>
    )
}

AccountSetupNav.propTypes = {
    handleSaveProgress: PropTypes.func,
    handleSubmit: PropTypes.func,
    completed: PropTypes.bool,
    hasPaidOutrightly: PropTypes.bool,
    showSimulateCreditDecision: PropTypes.bool,
    setShowSimulateCreditDecision: PropTypes.func,
    estimationId: PropTypes.string,
    openActionModal: PropTypes.func,
}

export default AccountSetupNav
