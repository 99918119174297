import { format, parseISO } from 'date-fns'
import '../paymentplanslistings.scss'
import PropTypes from 'prop-types'
import {
    approvalStatusMapping,
    onboardingTypeColorMapping,
    onboardingTypeMapping,
} from '../data'
import { useHistory } from 'react-router-dom'
import { titleCase } from 'src/utils/formatting'
import ButtonComponent from 'src/components/Button'
import StatusIndicator from 'src/components/StatusIndicator'
import { truncateSentence } from 'src/utils/formatting'
import CustomToolTip from 'src/components/CustomToolTip'

const PlansTable = ({ view, data }) => {
    const history = useHistory()
    const columnHeaders = [
        'Consumer Name',
        'Status',
        'Email address/Phone number',
        ...(view === 'DEP' ? ['Provider Name'] : []),
        'Date Created',
    ]

    function getConsumerName(item) {
        return item?.customer_type === 'RESIDENTIAL'
            ? item?.consumer?.user?.display_name
            : titleCase(
                  item?.consumer?.business?.business_name ||
                      item?.consumer?.user?.display_name ||
                      '',
              )
    }

    const handleNavigation = planId => {
        history.push(`/admin/plans/${planId}`)
    }

    const canUseNewOfferStatus =
        process.env.REACT_APP_FLAG_SHOW_NEW_OFFER_STATUS === 'true'

    const showUpdatedSSEFlow =
        process.env.REACT_APP_FLAG_SHOW_UPDATED_SSE_FLOW === 'true'

    return (
        <>
            <div className="PaymentPlansListings_Mobile_Wrapper">
                {data?.map((each, i) => (
                    <div className="PPLM_Card" key={i}>
                        <div>
                            {canUseNewOfferStatus && (
                                <StatusIndicator
                                    status={each?.application_status}
                                />
                            )}
                            <div className="updatedPaymentPlansListingsTr">
                                {!canUseNewOfferStatus && (
                                    <h4>CONSUMER NAME</h4>
                                )}
                                <p className="updatedPaymentPlansListingsP">
                                    {truncateSentence(
                                        getConsumerName(each),
                                        200,
                                    )}
                                </p>

                                {showUpdatedSSEFlow && (
                                    <span
                                        className="Tag_Wrapper"
                                        style={{
                                            backgroundColor:
                                                onboardingTypeColorMapping[
                                                    each.onboarding_type
                                                ]?.bgColor,
                                            color: onboardingTypeColorMapping[
                                                each.onboarding_type
                                            ]?.color,
                                        }}
                                    >
                                        {
                                            onboardingTypeMapping[
                                                each.onboarding_type
                                            ]
                                        }
                                    </span>
                                )}
                            </div>
                            <ButtonComponent
                                btnBgColor={'#e2eeff'}
                                btnTextColor={'#004AAD'}
                                btnHeight={'37px'}
                                btnWidth={'70px'}
                                handleClick={() => handleNavigation(each?.id)}
                            >
                                View
                            </ButtonComponent>
                        </div>

                        <div>
                            <div>
                                {canUseNewOfferStatus ? (
                                    <div className="PPLM_Card_EmailOrPhone">
                                        <h4>EMAIL/PHONE</h4>
                                        <p>
                                            {each?.consumer?.user
                                                ?.email_verified
                                                ? each?.consumer?.user?.email
                                                : each?.consumer?.user
                                                      ?.phone_number}
                                        </p>
                                    </div>
                                ) : (
                                    <>
                                        <h4>STATUS</h4>
                                        <div
                                            style={{
                                                background:
                                                    approvalStatusMapping[
                                                        each?.approval_status
                                                    ]?.bg,
                                                padding: 8,
                                            }}
                                        >
                                            <span
                                                style={{
                                                    color: approvalStatusMapping[
                                                        each?.approval_status
                                                    ]?.color,
                                                }}
                                            >
                                                {
                                                    approvalStatusMapping[
                                                        each?.approval_status
                                                    ]?.text
                                                }
                                            </span>
                                        </div>
                                    </>
                                )}
                            </div>

                            <div>
                                <h4 style={{ textAlign: 'right' }}>
                                    DATE CREATED
                                </h4>
                                <p
                                    style={{
                                        fontSize: '12px',
                                        textAlign: 'right',
                                    }}
                                >
                                    {each?.created_at !== undefined &&
                                        format(
                                            parseISO(each?.created_at),
                                            'dd MMM yyyy, HH:mm',
                                        )}
                                </p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div
                className="PaymentPlansListings_Table"
                data-testid="payment-plans-listings-table"
            >
                <table>
                    <thead>
                        <tr>
                            {columnHeaders?.map((each, i) => (
                                <th key={i}>{each}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((each, i) => (
                            <tr
                                className="PaymentPlansListings_Table_BodyRow"
                                key={i}
                                onClick={() => handleNavigation(each?.id)}
                            >
                                <td className="updatedPaymentPlansListingsTr">
                                    <div
                                        className={
                                            'PaymentPlansListings_Table_BodyRow_Flexbox'
                                        }
                                    >
                                        <CustomToolTip
                                            text={getConsumerName(each)}
                                            toolTipFontSize="12px"
                                            toolTipPadding="6px 11px"
                                            toolTipPopupWidth={'fit-content'}
                                            toolTipMarginLeft={-24}
                                            arrowPositionCenter
                                            preventLineBreak
                                        >
                                            <p
                                                className={
                                                    'PaymentPlansListings_Table_BodyRow_Name'
                                                }
                                            >
                                                {truncateSentence(
                                                    getConsumerName(each),
                                                    8,
                                                )}
                                            </p>
                                        </CustomToolTip>

                                        {showUpdatedSSEFlow &&
                                            each.onboarding_type !== null && (
                                                <span
                                                    className="Tag_Wrapper"
                                                    style={{
                                                        backgroundColor:
                                                            onboardingTypeColorMapping[
                                                                each
                                                                    .onboarding_type
                                                            ]?.bgColor,
                                                        color: onboardingTypeColorMapping[
                                                            each.onboarding_type
                                                        ]?.color,
                                                    }}
                                                >
                                                    {
                                                        onboardingTypeMapping[
                                                            each.onboarding_type
                                                        ]
                                                    }
                                                </span>
                                            )}
                                    </div>
                                </td>

                                {canUseNewOfferStatus ? (
                                    <td>
                                        <StatusIndicator
                                            status={each?.application_status}
                                        />
                                    </td>
                                ) : (
                                    <td className="PaymentPlansListings_Table_BodyRow_Tag">
                                        <div
                                            style={{
                                                background:
                                                    approvalStatusMapping[
                                                        each?.approval_status
                                                    ]?.bg,
                                            }}
                                        >
                                            <span
                                                style={{
                                                    color: approvalStatusMapping[
                                                        each?.approval_status
                                                    ]?.color,
                                                }}
                                            >
                                                {
                                                    approvalStatusMapping[
                                                        each?.approval_status
                                                    ]?.text
                                                }
                                            </span>
                                        </div>
                                    </td>
                                )}
                                <td>
                                    <CustomToolTip
                                        text={
                                            each?.consumer?.user?.email_verified
                                                ? each?.consumer?.user?.email
                                                : each?.consumer?.user
                                                      ?.phone_number
                                        }
                                        toolTipFontSize="12px"
                                        toolTipPadding="6px 11px"
                                        toolTipPopupWidth={'fit-content'}
                                        toolTipMarginLeft={-24}
                                        arrowPositionCenter
                                        preventLineBreak
                                    >
                                        {each?.consumer?.user?.email_verified
                                            ? truncateSentence(
                                                  each?.consumer?.user?.email,
                                                  14,
                                              )
                                            : each?.consumer?.user
                                                  ?.phone_number}
                                    </CustomToolTip>
                                </td>
                                {view === 'DEP' && (
                                    <td>
                                        <CustomToolTip
                                            text={each?.provider?.display_name}
                                            toolTipFontSize="12px"
                                            toolTipPadding="6px 11px"
                                            toolTipPopupWidth={'fit-content'}
                                            toolTipMarginLeft={-24}
                                            arrowPositionCenter
                                            preventLineBreak
                                        >
                                            {truncateSentence(
                                                each?.provider?.display_name,
                                                14,
                                            )}
                                        </CustomToolTip>
                                    </td>
                                )}
                                <td>
                                    {each?.created_at !== undefined &&
                                        format(
                                            parseISO(each?.created_at),
                                            'dd MMM yyyy, HH:mm',
                                        )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}

PlansTable.propTypes = {
    view: PropTypes.oneOf(['DEP', 'DTC']),
    data: PropTypes.array,
}

export default PlansTable
