import { createSlice } from '@reduxjs/toolkit'
import storageSession from 'redux-persist/lib/storage/session'
import { persistReducer } from 'redux-persist'

const initialState = {
    member_status: [],
    due_payment: '',
    system_size: '',
    payment_type: '',
    payment_amount: '',
    page: '1',
    name: '',
}

const clusterRepMembersListing = createSlice({
    name: 'clusterRepMembersListingFilter',
    initialState,
    reducers: {
        updateClusterRepMembersFilter(state, action) {
            return {
                ...state,
                ...action.payload,
            }
        },
        resetClusterRepMembersFilter(state) {
            return {
                ...state,
                ...initialState,
            }
        },
    },
})

export const { updateClusterRepMembersFilter, resetClusterRepMembersFilter } =
    clusterRepMembersListing.actions

export const updatedClusterRepMembersFilters = state => {
    state.clusterRepMembersListingFilter
}

const persistConfig = {
    key: 'clusterRepMembersListingFilter',
    storage: storageSession,
}

export const persistedClusterRepMembersListingReducer = persistReducer(
    persistConfig,
    clusterRepMembersListing.reducer,
)

export default clusterRepMembersListing.reducer
