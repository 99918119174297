import { Link, useLocation, useParams } from 'react-router-dom'
import { ReactComponent as Arrow } from 'src/assets/images/blue-circle-right.svg'
import { ReactComponent as CopyIcon } from 'src/assets/images/copy2-icon.svg'
import { useEffect, useState } from 'react'
import { Skeleton, Tabs } from 'antd'
import queryString from 'query-string'
import { useCustomToast } from 'src/utils/Hooks/useToast'
import PropType from 'prop-types'
import { BasicInfoLoaders } from '../Loaders'
import TabPaneAndTableOneSkeleton from 'src/components/AdminSkeletons/TabPaneAndTableOne'
import { approvalStatusMap, consumerTypeMap } from '../../utils/utils'
import ApprovedToDoTable from 'src/pages/Admin/PaymentPlans/Details/components/ApprovedToDoTable'
import SecondaryModal from 'src/components/Modal/SecondaryModal'
import styles from './MainDetails.module.scss'
import { usePlanContext } from '../../hooks/planProvider'
import ApprovedModal from '../ApprovedModal'
import ActionModal from 'src/components/ActionModal'
import { AppButton } from 'src/components/Button'
import InvestmentMetrics from '../InvestmentMetrics'
import RepaymentScheduleTable from '../RepaymentScheduleTable'
import CreditReviewTab from '../creditReviewTab/CreditReviewTab'
import TechnicalReviewTab from '../technicalReviewTab/TechnicalReviewTab'
import { getCreditDecision } from 'src/api/admin/payment-plans/ssePlan'
import { useQuery } from 'react-query'
import { formatInfoFromDep } from 'src/pages/Admin/PaymentPlans/Details/utils/data'
import { errorHandler } from 'src/utils/errorHandler'
import moment from 'moment'
import { titleCase } from 'src/utils/formatting'
import ConsumerInfoTable from '../ConsumerInfoTable'
import AlternativePackages from '../AlternativePackages'
import { getAltFullName } from 'src/pages/Admin/AlternateRecommendation/Listings/utils'
import useClosePlan from '../../hooks/useClosePlan'
import StatusIndicator from 'src/components/StatusIndicator'
import { planDetails } from '../../utils/data'
import useMediaQueries from 'src/utils/Hooks/useMediaQueries'
import CloseureActionModal from '../ClosureActionModal'
import './maindetails.scss'
import {
    onboardingTypeMapping,
    onboardingTypeColorMapping,
} from '../../../Listings/data'
import { removeCountryCode } from 'src/utils/NumberFormatter'

const { TabPane } = Tabs
const MainDetails = ({
    isPaymentPlanLoading,
    paymentPlanData,
    recommendationType,
    isOutrightSale,
}) => {
    const { successAlert, errorAlert } = useCustomToast()
    const [currentTab, setCurrentTab] = useState()
    const [showCalendar, setShowCalendar] = useState(0)
    const [showInvestmentMetricsModal, setShowInvestmentMetricsModal] =
        useState(false)
    const [creditDecisionData, setCreditDecisionData] = useState({})
    const [showCloseWarningModal, setShowCloseWarningModal] = useState(false)
    const params = useParams()
    const location = useLocation()
    const parsed = queryString.parse(location?.search)

    const { isMobile } = useMediaQueries()

    const {
        setToDoContent,
        openModal,
        setOpenModal,
        successModal,
        setSuccessModal,
        successHeaderText,
        inputs,
        setInputs,
        setModalDisplayValues,
        setHour,
        setMinute,
        setTime,
        setCurrentDatePaid,
        setRepaymentDate,
        setDateTime,
        setShowTimeDropdown,
        setSuccessUpload,
        setErrors,
        setUploadError,
        setShowUploadStandingOrder,
        setShowPaystackSubscriptionCode,
        showPackagesDrawer,
        setShowPackagesDrawer,
        successModalSubtitle,
    } = usePlanContext()

    const {
        closePlanMutate,
        closePlanLoading,
        closePlanErrorMessage,
        closePlanToastError,
    } = useClosePlan(params?.id, inputs.closureReason, () =>
        setShowCloseWarningModal(false),
    )
    const canUseNewOfferStatus =
        process.env.REACT_APP_FLAG_SHOW_NEW_OFFER_STATUS === 'true'
    const showUpdatedSSEFlow =
        process.env.REACT_APP_FLAG_SHOW_UPDATED_SSE_FLOW === 'true'

    const status =
        recommendationType === 'alternative' &&
        paymentPlanData?.medium ===
            'SSE_CONSUMER_DROP_OFF_REQUEST_MANUAL_RECOMMENDATION'
            ? 'AWAITING_SOLUTION'
            : recommendationType === 'alternative' &&
              paymentPlanData?.medium ===
                  'SSE_CONSUMER_DROP_OFF_RECEIVED_MANUAL_RECOMMENDATIONS_FROM_ADMIN'
            ? 'SOLUTION_PROVIDED'
            : paymentPlanData?.approval_status
    const user =
        recommendationType === 'alternative'
            ? paymentPlanData
            : paymentPlanData?.consumer?.user
    const userEmail = user?.email || user?.form_question?.custom_email || null
    const userPhoneNumber =
        user?.phone_number || user?.form_question?.custom_phone_number

    // close modal and reset values
    const localHandleModal = () => {
        setHour('')
        setMinute('')
        setTime('')
        setCurrentDatePaid('')
        setRepaymentDate('')
        setDateTime('')
        setShowUploadStandingOrder(false)
        setShowPaystackSubscriptionCode(false)
        setShowCalendar(0)
        setInputs({
            dateOfAction: null,
            firstRepaymentDate: null,
            timeOfAction: null,
            dateTime: '',
            repaymentMonth: null,
            repaymentMethod: paymentPlanData?.repayment_method_setup?.type,
            evidenceOfAction: [],
            repaymentMethodSetupType: null,
            repaymentMethodSetupSubscriptionCode: '',
            installedSolutionEvidenceDriveLink: '',
            updatePlanTenure: null,
            updatePlanTenurePlanId: null,
            closureReason: null,
            recordRepaymentTotalAmount: 0,
            upfrontDepositTotalAmount: 0,
            providerPaymentTotalAmount: 0,
        })
        setErrors('')
        setUploadError(false)
        setSuccessUpload([])
        setShowTimeDropdown(false)
        setOpenModal(false)
    }

    const handleCopy = (field, text) => {
        navigator.clipboard.writeText(text)
        if (field === 'email') {
            successAlert('Consumer email address copied')
        } else if (field === 'phone') {
            successAlert('Consumer phone number copied')
        }
    }

    // display modal under to-do table & set values
    const handleToDoModal = info => {
        setToDoContent([info])
        setModalDisplayValues('To Do Table')
        setOpenModal(!openModal)
    }

    const handleShowInvestmentMetricsModal = () => {
        setShowInvestmentMetricsModal(true)
    }

    const { refetch: refetchCreditDecision } = useQuery(
        'get-credit-decision',
        () => getCreditDecision(params?.id),
        {
            retry: false,
            enabled: false,
            onSuccess: data => {
                setCreditDecisionData(data?.data?.data)
            },
            onError: error => {
                errorAlert(errorHandler(error?.response?.data))
            },
        },
    )

    useEffect(() => {
        if (recommendationType === 'auto') {
            refetchCreditDecision()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recommendationType])

    const tabPaneData = [
        {
            label: 'Info from Provider',
            visible:
                paymentPlanData?.survey_type === 'DFS'
                    ? [
                          'AWAITING_APPROVAL',
                          'DECLINED',
                          'IN_PROGRESS',
                          'CLOSED',
                          'AWAITING_UPDATE',
                      ]
                    : [],
            content: (
                <div className={styles.ContentWrapper}>
                    {formatInfoFromDep(paymentPlanData).map((info, i) => (
                        <div key={i} className={styles.TableNoHeader}>
                            <p className={styles.NoHeaderName}>{info?.label}</p>
                            <p className={styles.NoHeaderContent}>
                                {info?.id === 4 ? (
                                    info.value === 0 ? (
                                        'Not Set'
                                    ) : (
                                        <AppButton
                                            btnTextColor={'#004AAD'}
                                            btnBgColor={'#E2EEFF'}
                                            type="buttonTwo"
                                            handleClick={() => {
                                                setCurrentTab('2')
                                            }}
                                        >
                                            View
                                        </AppButton>
                                    )
                                ) : (
                                    info?.value
                                )}
                            </p>
                        </div>
                    ))}
                </div>
            ),
        },
        {
            label: 'To Dos',
            visible: ['APPROVED'],
            content: (
                <ApprovedToDoTable
                    data={paymentPlanData}
                    handleModal={handleToDoModal}
                    customCallBack={() => setCurrentTab('5')}
                    isOutrightSale={isOutrightSale}
                    sseUse
                />
            ),
        },
        {
            label: `Consumer's Info`,
            visible: ['AWAITING_SOLUTION', 'SOLUTION_PROVIDED'],
            content: <ConsumerInfoTable data={paymentPlanData} />,
        },
        {
            label: 'Technical Review',
            visible: [
                'APPROVED',
                'AWAITING_APPROVAL',
                'AWAITING_UPDATE',
                'DECLINED',
                'IN_PROGRESS',
                'CLOSED',
                'AWAITING_SOLUTION',
                'SOLUTION_PROVIDED',
            ],
            content: (
                <TechnicalReviewTab
                    appliances={
                        recommendationType === 'alternative'
                            ? paymentPlanData?.form_question?.appliances
                            : paymentPlanData?.survey_type === 'DFS'
                            ? paymentPlanData?.appliances
                            : paymentPlanData?.consumer_form_questions
                                  ?.appliances
                    }
                    DEPSolution={
                        paymentPlanData?.survey_type === 'DFS' ||
                        paymentPlanData?.is_promo_estimation
                            ? paymentPlanData?.recommended_solution
                            : null
                    }
                    paymentPlans={paymentPlanData?.repayment_plans}
                    recommendationType={recommendationType}
                    handleShowAlternativePackages={() =>
                        setShowPackagesDrawer(true)
                    }
                    alternativeRecommendations={
                        paymentPlanData?.previous_recommendations
                    }
                    status={status}
                    energyProfile={
                        onboardingTypeMapping[
                            paymentPlanData?.onboarding_type
                        ] === 'Profile'
                            ? paymentPlanData?.energy_profile
                            : ''
                    }
                />
            ),
        },
        {
            label: 'Credit Review',
            visible: isOutrightSale
                ? []
                : [
                      'APPROVED',
                      'AWAITING_APPROVAL',
                      'AWAITING_UPDATE',
                      'IN_PROGRESS',
                      'DECLINED',
                      'CLOSED',
                  ],
            content: (
                <CreditReviewTab
                    creditDecisionData={creditDecisionData}
                    planType={paymentPlanData?.payment_plan}
                    customerType={paymentPlanData?.customer_type}
                    estimationId={params?.id}
                    status={status}
                />
            ),
        },
        {
            label: 'Plan Details',
            visible: isOutrightSale ? [] : ['APPROVED'],
            content: (
                <div className={styles.ContentWrapper}>
                    {planDetails(paymentPlanData || [])?.map((info, i) => (
                        <div key={i} className={styles.TableNoHeader}>
                            <p className={styles.NoHeaderName}>{info.label}</p>
                            <p className={styles.NoHeaderContent}>
                                {info.value}
                            </p>
                        </div>
                    ))}
                    <div className={styles.TableNoHeader}>
                        <p className={styles.NoHeaderName}>
                            Investment Metrics
                        </p>
                        <p className={styles.NoHeaderContent}>
                            <AppButton
                                btnWidth="70px"
                                btnHeight="37px"
                                btnBgColor="#E2EEFF"
                                btnTextColor="#004AAD"
                                disabled={
                                    paymentPlanData?.repayment_schedule_investment_meta_data &&
                                    Object.entries(
                                        paymentPlanData?.repayment_schedule_investment_meta_data,
                                    ).length === 0
                                }
                                handleClick={() =>
                                    handleShowInvestmentMetricsModal()
                                }
                            >
                                View
                            </AppButton>
                        </p>
                    </div>
                </div>
            ),
        },
        {
            label: 'Repayment Schedule',
            visible: isOutrightSale ? [] : ['APPROVED'],
            content: (
                <RepaymentScheduleTable
                    data={paymentPlanData}
                    page={
                        paymentPlanData?.survey_type === 'CFS'
                            ? 'dtc-requests'
                            : ''
                    }
                />
            ),
        },
    ]

    useEffect(() => {
        if (Object.keys(parsed).length > 0) {
            switch (parsed.tab) {
                case 'credit-review':
                    setCurrentTab('4')
                    break
            }
        }
    }, [parsed])

    return (
        <>
            {/* modal for the items under the to-do table */}
            <SecondaryModal
                showModal={openModal}
                modalWidth="624px"
                modalHeight={'fit-content'}
                noPadding={isMobile ? '24px 16px' : '48px 80px'}
                modalClass="RecommendedEnergy"
                onCancel={localHandleModal}
                content={
                    <ApprovedModal
                        estimationId={params?.id}
                        estimationData={paymentPlanData}
                        showCalendar={showCalendar}
                        setShowCalendar={setShowCalendar}
                        openClosePlanActionModal={() =>
                            setShowCloseWarningModal(true)
                        }
                    />
                }
            />

            <ActionModal
                actionModalOpen={successModal}
                headerText={successHeaderText}
                subTitle={
                    successModalSubtitle
                        ? successModalSubtitle
                        : 'We solved the problems that make it difficult for energy providers to transition millions'
                }
                closeModal={() => {
                    setSuccessModal(false)
                    localHandleModal()
                }}
                actionHandler={() => {
                    setSuccessModal(false)
                    localHandleModal()
                    if (
                        successHeaderText ===
                            'Payment Plan Closed Successfully' ||
                        successHeaderText === 'Payment evidence submitted'
                    ) {
                        window.location.reload()
                    }
                }}
                actionType="success"
                actionText="Okay, Got it"
                loading={''}
                noCancelBtn={true}
                closable={
                    successHeaderText === 'Payment Plan Closed Successfully'
                }
                onCancel={() => {
                    setSuccessModal(false)
                    window.location.reload()
                }}
                noBackLink
            />

            <SecondaryModal
                modalWidth="550px"
                modalHeight="fit-content"
                modalClass="RecommendedEnergy"
                noPadding="70px"
                showModal={showInvestmentMetricsModal}
                onCancel={() => setShowInvestmentMetricsModal(false)}
                content={
                    <InvestmentMetrics
                        closeModal={() => setShowInvestmentMetricsModal(false)}
                        investmentData={paymentPlanData}
                    />
                }
            />

            <CloseureActionModal
                estimationType="Payment Plan"
                actionModalOpen={showCloseWarningModal}
                closeModal={() => setShowCloseWarningModal(false)}
                onCancel={() => setShowCloseWarningModal(false)}
                actionHandler={() => closePlanMutate()}
                loading={closePlanLoading}
                errorMessage={closePlanErrorMessage}
                toastError={closePlanToastError}
            />

            {/* drawer */}
            <AlternativePackages
                showAlternativePackages={showPackagesDrawer}
                setShowAlternativePackages={setShowPackagesDrawer}
                subscriptionSupported={
                    paymentPlanData?.form_question?.payment_model ===
                    'Subscription'
                }
                leaseToOwnSupported={
                    paymentPlanData?.form_question?.payment_model ===
                    'Lease to Own'
                }
                canEnableFetch={Object?.keys(paymentPlanData)?.length > 0}
            />

            <section className={styles.mainPlanDetails}>
                <div className={styles.mainPlanDetails__top}>
                    {isPaymentPlanLoading ? (
                        <Skeleton
                            title={{ width: 220, height: 25 }}
                            paragraph={false}
                        />
                    ) : (
                        <div
                            className={
                                paymentPlanData?.application_status
                                    ? styles.nameAndStatus
                                    : ''
                            }
                        >
                            <h3>
                                {paymentPlanData?.customer_type ===
                                'RESIDENTIAL'
                                    ? user?.display_name
                                    : paymentPlanData?.customer_type ===
                                      'BUSINESS'
                                    ? titleCase(
                                          paymentPlanData?.consumer?.business
                                              ?.business_name ||
                                              user?.display_name,
                                      )
                                    : getAltFullName(user?.form_question)}

                                {(!canUseNewOfferStatus ||
                                    recommendationType === 'alternative') && (
                                    <span
                                        style={{
                                            background:
                                                approvalStatusMap[status]
                                                    ?.background,
                                            color: approvalStatusMap[status]
                                                ?.color,
                                        }}
                                    >
                                        {approvalStatusMap[status]?.label}
                                    </span>
                                )}
                                {/* logic to display this only if the tag is profile */}
                            </h3>
                            {showUpdatedSSEFlow &&
                                paymentPlanData.onboarding_type !== null && (
                                    <span
                                        className="Tag_Wrapper"
                                        style={{
                                            backgroundColor:
                                                onboardingTypeColorMapping[
                                                    paymentPlanData
                                                        ?.onboarding_type
                                                ]?.bgColor,
                                            color: onboardingTypeColorMapping[
                                                paymentPlanData?.onboarding_type
                                            ]?.color,
                                        }}
                                    >
                                        {
                                            onboardingTypeMapping[
                                                paymentPlanData?.onboarding_type
                                            ]
                                        }
                                    </span>
                                )}
                            {canUseNewOfferStatus &&
                                recommendationType !== 'alternative' && (
                                    <StatusIndicator
                                        status={
                                            paymentPlanData?.application_status
                                        }
                                        page={
                                            paymentPlanData?.payment_plan ===
                                                'Outright Sale' &&
                                            'outright-sale'
                                        }
                                    />
                                )}
                        </div>
                    )}

                    {isPaymentPlanLoading ? (
                        <BasicInfoLoaders />
                    ) : (
                        <div className={styles.mainPlanDetails__top__info}>
                            <div>
                                <h6>EMAIL ADDRESS</h6>
                                <p>
                                    {userEmail ?? 'N/A'}{' '}
                                    {userEmail ? (
                                        <CopyIcon
                                            onClick={() =>
                                                handleCopy('email', userEmail)
                                            }
                                        />
                                    ) : null}
                                </p>
                            </div>
                            <div>
                                <h6>PHONE NUMBER</h6>
                                <p>
                                    {removeCountryCode(userPhoneNumber) ??
                                        'N/A'}{' '}
                                    {userPhoneNumber ? (
                                        <CopyIcon
                                            onClick={() =>
                                                handleCopy(
                                                    'phone',
                                                    userPhoneNumber,
                                                )
                                            }
                                        />
                                    ) : null}
                                </p>
                            </div>
                            {recommendationType !== 'alternative' && (
                                <Link
                                    to={
                                        isOutrightSale
                                            ? `/admin/outright-sale/${params?.id}/kyc/information`
                                            : `/admin/plans/${params?.id}/kyc`
                                    }
                                >
                                    Full KYC <Arrow />
                                </Link>
                            )}
                        </div>
                    )}
                </div>
                <div
                    className={styles.mainPlanDetails__mobile}
                    id="planDetailMobile"
                >
                    <h3>OFFER DETAILS</h3>

                    <div className={styles.mainPlanDetails__mobile__items}>
                        <div>
                            <h6>Creation date</h6>
                            <p>
                                {moment(paymentPlanData?.created_at).format(
                                    'DD MMM, YYYY, HH:mm',
                                )}
                            </p>
                        </div>
                        {paymentPlanData?.survey_type === 'DFS' ? (
                            <div>
                                <h6>Created by</h6>
                                <p>
                                    {paymentPlanData?.created_by?.display_name}
                                </p>
                            </div>
                        ) : null}
                        <div>
                            <h6>Consumer type</h6>
                            <p>
                                {recommendationType === 'alternative'
                                    ? 'DTC'
                                    : paymentPlanData?.survey_type === 'CFS'
                                    ? 'DTC'
                                    : paymentPlanData?.survey_type === 'DFS'
                                    ? 'DEP'
                                    : ''}
                            </p>
                        </div>
                        <div>
                            <h6>Last updated</h6>
                            <p>
                                {moment(paymentPlanData?.updated_at).format(
                                    'DD MMM, YYYY, HH:mm',
                                )}
                            </p>
                        </div>
                        <div>
                            <h6>Solution type</h6>
                            <p>
                                {consumerTypeMap[
                                    paymentPlanData?.customer_type
                                ] || '--'}
                            </p>
                        </div>
                    </div>
                </div>
                <div>
                    {isPaymentPlanLoading ? (
                        <TabPaneAndTableOneSkeleton />
                    ) : (
                        <Tabs
                            activeKey={currentTab}
                            onChange={values => {
                                setCurrentTab(values)
                            }}
                            defaultActiveKey={currentTab}
                            className="ApprovedTabsWrapper"
                        >
                            {tabPaneData?.map((panel, index) =>
                                panel.visible.includes(status) ? (
                                    <TabPane tab={panel.label} key={`${index}`}>
                                        {panel.content}
                                    </TabPane>
                                ) : null,
                            )}
                        </Tabs>
                    )}
                </div>
            </section>
        </>
    )
}

export default MainDetails

MainDetails.propTypes = {
    isPaymentPlanLoading: PropType.bool,
    paymentPlanData: PropType.object,
    recommendationType: PropType.string,
    isOutrightSale: PropType.bool,
}
